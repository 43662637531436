<template>
  <div v-show="isEditing">
    <form novalidate @submit.prevent="checkForm">
      <div v-if="saveInProgress" class="spinner-box">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <div v-if="postSaved" class="alert alert-success" role="alert">
        <span> Post saved successfully. </span>
      </div>
      <div v-if="error && error.message" class="alert alert-danger" role="alert">
        <span> An error occurred {{ error.message }}</span>
      </div>
      <div v-if="imageUploadMessage" class="alert alert-danger" role="alert">
        <span> An error occurred {{ imageUploadMessage }}</span>
      </div>

      <cc-input v-bind="{type: 'checkbox', ...schema.hideFromListing , model: $v.hideFromListing}"/>
      <cc-input v-bind="{type: 'text', ...schema.topic , model: $v.topic}"/>
      <cc-input v-bind="{type: 'text', ...schema.tagsJoined , model: $v.tagsJoined}"/>
      <cc-input
          v-bind="{type: 'select', ...schema.category , model: $v.category, options: schema.category.options}"/>

      <image-upload v-bind="{
          imageUrl,
          uploadStatus:imageUploadStatus,
          imageUploadMessage,
          id,
          mediaFiles: currentPost.mediaFiles,
        }"
                    @on-insert="insertImage"
                    @on-media-removed="removeMedia"
                    imageType="postBanner"
      />

      <div class="image-selector">
        <label> Main Image </label>
        <div class="image-selector__container">
          <img v-bind:src="bannerImagePath"/>
          <cc-input
              v-bind="{type: 'multisizeImageSelect', options: {list: currentPost.mediaFiles, label: 'url', value: 'index'}, ...schema.bannerImageIndex , model: $v.bannerImageIndex, icon:'tag'}"/>
        </div>
      </div>

      <cc-input v-bind="{type: 'textarea', ...schema.extract , model: $v.extract, rows: 5}"/>
      <md-textarea v-bind="{...schema.content, content: content, model: $v.content, rows: 20, cols: 120}"/>

      <div class="row">
        <cc-input
            v-bind="{type: 'select', ...schema.previousLink , model: $v.previousLink, options: articleLinkOptions}" class="large-half"/>
        <cc-input
            v-bind="{type: 'select', ...schema.nextLink , model: $v.nextLink, options: articleLinkOptions}"  class="large-half" />
      </div>

      <div class="row">
        <button id="submitBtn" class="btn--large btn--primary" v-bind:disabled="saveInProgress" v-on:click="checkForm">
          <div v-if="saveInProgress">
            <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
            In Progress...
          </div>
          <div v-if="!saveInProgress">
            Save
          </div>
        </button>
        <button class="btn--large btn--stroke" type="cancel" v-on:click="viewAllPosts">Cancel
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import ImageUpload from "../../media/ImageUpload";
import {NETWORK_REQUEST} from "../../../store/initialise";
import schema from './form' ;
import validator from '../../../services/Validation/Validation';
import ccInput from "../inputs/ccInput";
import {mapState} from "vuex";
import MdTextarea from "@/components/media/MdTextarea.vue";

export default {
  name: 'editor',
  props: ['post', 'imageUrl', 'requestStatus'],
  components: {
    MdTextarea,
    ImageUpload,
    ccInput,
  },
  data() {
    const {id: id, topic, content, extract, tags, bannerImageIndex, mediaFiles, category, nextLink, previousLink,
      hideFromListing} = {...this.post};

    const tagsJoined = tags.join(',');
    return {
      schema,
      isEditing: true,
      id,
      content,
      extract,
      topic,
      tagsJoined,
      category,
      bannerImageIndex,
      mediaFiles,
      nextLink,
      previousLink,
      hideFromListing,
    };
  },
  validations: validator.getValidation(schema),
  computed: {
    ...mapState([
      'imageUploadStatus',
      'imageUploadMessage',
      'error',
      'postSaved',
      'currentPost',
      'publishedPostNames'
    ]),
    editingPost: function () {
      return {...this.post}
    },
    renderedMarkDown: function () {
      return this.$md.render(this.content)
    },
    saveInProgress: function () {
      return this.requestStatus === NETWORK_REQUEST.IN_PROGRESS;
    },
    bannerImagePath: function () {
      const mediaFiles = this.currentPost.mediaFiles;
      return mediaFiles && this.bannerImageIndex > 0 ? mediaFiles[this.bannerImageIndex] : null;
    },
    articleLinkOptions: function() {
      const options = this.publishedPostNames || [];
      return options.map( ({topic, slug, id}) => ({label: topic, value: {id, topic, slug}}));
    }
  },
  methods: {
    clickSubmit() {
      document.querySelector('#submitBtn').click();
    },
    checkForm() {
      // event.preventDefault();
      document.querySelector('form').classList.add('was-validated');

      Object.keys(schema).forEach(x => {
        const field = document.querySelector(`#${x}Input`);
        const customValidity = this.$v[x].$invalid ? 'invalid' : '';
        if (field) {
          field.setCustomValidity(customValidity);
        }
      });

      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        console.log('is invalid');
      } else {
        // do your submit logic here
        this.sendForm();
      }
    },
    sendForm() {
      const {topic, content, extract, tagsJoined, bannerImageIndex, category, nextLink, previousLink,
        hideFromListing} = this;
      const mediaFiles = this.currentPost.mediaFiles;
      const tags = tagsJoined.split(',');
      this.$emit('save-post', {
        ...this.post,
        topic,
        content,
        extract,
        tags,
        bannerImageIndex,
        mediaFiles,
        category,
        nextLink,
        previousLink,
        hideFromListing,
      });

      this.isFormVisible = true;
    },
    insertImage(url) {
      this.content = `![image](${url}){width=500px}` + this.content;
    },
    viewAllPosts() {
      this.$appRouter.pushRoute('MY_POSTS');
    },
    removeMedia(media, idx){
      this.mediaFiles.splice(idx, 1);
    }
  },
  beforeCreate: function () {
    this.$store.dispatch('LOAD_PUBLISHED_POSTS_NAMES');
  },
  created: function () {
    this.$bus.subscribe('SAVE_CURRENT_POST', this.clickSubmit);
  },
  beforeDestroy() {
    this.$bus.unsubscribe('SAVE_CURRENT_POST', this.clickSubmit);
  }
}
</script>

<style lang="scss">
textarea#content {
  width: 100%;
  height: 500px;
}

.spinner-box {
  position: static;
  height: 100%;
  width: 100%;
  color: black;
  background-color: #CCCCCC;
  opacity: 1;
}

.spinner-border {
  position: absolute;
  top: 50%;
  left: 50%;
}

.image-selector {
  &__container {
    img {
      width: 20%;
    }

    select {
      width: 75%;
    }
  }

  display: flex;
  flex-direction: column;
}

#contentInput {
  min-width: 100%;
}
</style>
