<template>
  <div :class="{ 'form-group--error': model.$error, 'form-group--valid': !model.$error}">
    <label class="form__label" :class="{'h-add-bottom': type ==='checkbox'}">
      <template v-if="type !== 'checkbox'">{{ label }}</template>
      <input v-if="type === 'checkbox'" :type="type" :id="inputId" v-model="model.$model"
             :true-value="trueValue"
             :false-value="falseValue"
      />
      <span class="label-text" v-if="type === 'checkbox'">{{ label }}</span>
    </label>
    <input v-if="['text', 'email'].includes(type)" :type="type" :id="inputId" :placeholder="placeholder"
           class="h-full-width" v-model.trim="model.$model"/>
    <textarea v-if="type==='textarea'" :id="inputId" :rows="rows" :cols="cols" :readonly="readonly" class="h-full-width"
              v-model.trim="model.$model"/>
    <select v-if="type==='select'" class="browser-default custom-select" v-model="model.$model">
      <option v-for="(item, idx) in options" :key="idx" :value="item.value">{{ item.label }}</option>
    </select>
    <select v-if="type==='multisizeImageSelect'" class="browser-default custom-select" v-model="model.$model">
      <option v-for="(item, idx) in options.list" :key="idx"
              :value="options.value === 'index' ? idx : item[options.value]">Image #{{ idx }}
      </option>
    </select>
    <div class="invalid-feedback alert-box alert-box--error hideit" v-for="key in messageKeys" :key="key">
      {{ messages[key].message }}
    </div>
  </div>
</template>

<script>
import validator from '../../../services/Validation/Validation';

/* eslint-disable */

export default {
  name: 'cc-input',
  props: ['id', 'type', 'model', 'label', 'default', 'icon', 'rows', 'cols', 'options', 'rules', 'placeholder', 'readonly', 'trueValue', 'falseValue'],
  components: {},
  computed: {
    messages: function () {
      return validator.getMessages(this.model, this.rules);
    },
    messageKeys: function () {
      return this.msgKeys.filter(k => this.model[k] === false);
    }
  },
  data() {
    return {
      inputId: this.id + "Input",
      msgKeys: Object.keys(this.rules)
    };
  }
}
</script>

<style lang="scss">
.invalid-feedback {
  display: none;
}

.was-validated .invalid-feedback {
  display: block;

}


</style>
