<template>
    <div>
        <vue-headful :title="pageTitle"
                     :description="pageDescription"
                     :keyword="keywords"
                     :head="{
                      'link[rel=canonical]': {href: canonicalURL},
                      'meta[name=robots]' : {content: isPrivate ? 'noindex' : 'all'},
                      'link[rel=preload]': lcpHref ? {href: lcpHref, fetchpriority:'high', as:'image', type: lcpMimeType } : false,
      }"
        />
    </div>
</template>

<script>
  import vueHeadful from 'vue-headful';

  export default {
    name: "CeTitle",
    props: ['dynamicTitle', 'dynamicDescription', 'fromRoute', 'isPrivate', 'lcpHref', 'lcpMimeType'],
    computed: {
      pageTitle() {
        if (this.fromRoute === "") {
          return `${this.title} - ${this.appTitle}`;
        }

        const dTitle = this.dynamicTitle;
        const title = dTitle && dTitle.substring(0, Math.min(50, dTitle.length)) || '';
        return  `${title} - Prefactor.io`;
      },
      canonicalURL() {
        return `${process.env.VUE_APP_CANONICAL_HOSTNAME}${window.location.pathname}${window.location.search}`;
      },
      pageDescription() {
        if ( this.fromRoute === "") {
          return this.description;
        }

        return this.dynamicDescription;
      },
    },
    components: {
      vueHeadful,
    },
    data: function () {
      const {meta} = this.$route ;
      if ( ! meta) {
        throw `Route ${this.$route} does not have a meta tag.  All Routes required to have meta tag defined as part of the route`;
      }

      const titleFromRoute = meta.title;
      const descFromRoute = meta.description;

      return {
        appTitle: 'Prefactor.io',
        appDescription: 'Prefactor is a site targeted to creating content.',
        title: titleFromRoute,
        description: descFromRoute,
        keywords: "prefactor, software architecture, digital architecture",
      }
    },
  }
</script>
