import validator from 'validator';

export const minLength = (length) => (str) => validator.isLength(str, {min: length});

export const maxLength = (length) => (str="") => {
  const isValid =  validator.isLength(str, {min:0, max: length}) ;
  return isValid;
}

export const isNotEmpty = () => (str) => ! validator.isEmpty(str);

export const url = () => (str) => {
  if (!str || str == '')
    return true;

  return typeof str === 'string' && validator.isURL(str);
}

export const length = () => (str) => validator.isLength(str);

/**
 * For some reason, this rule does not work when being added to the mapping, while the one from vuevalidation does.
 */
export const email = () => (str) => {
  return validator.isEmail(str);
}

