<template>
  <div id="app">
    <loading-spinner />
    <app-menu :mode="this.menuMode" :auth="auth" v-bind="{groups}" :is-logged-in="isLoggedIn" :is-admin-user="isAdminUser"></app-menu>
    <div>
      <div class="s-content">
        <div class="row">
          <div id="main" class="large-full column">
            <router-view :key="$route.fullPath" ></router-view>
          </div>
        </div>
      </div>
      <page-footer :isVisible="isFooterVisible" :isScrolled="isWindowScrolled"/>
    </div>
  </div>
</template>

<script>
import AppMenu from './components/AppMenu';
import store from './store/initialise';
import {mapState} from "vuex";
import {menuConf} from "./routes";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import intersectionObservableMixin from "@/mixins/intersectionObservable";
import Sidebar from "@/components/Sidebar.vue";
export default {
  name: 'app',
  store,
  components: {
    Sidebar,
    LoadingSpinner,
    AppMenu,
  },
  data: function() {
    return {
      isWindowScrolled: false,
    }
  },
  computed: {
    ...mapState([
      'auth',
    ]),
    isLoggedIn: function () {
      return this.auth && this.$authService && this.$authService.isSessionCurrent();
    },
    isAdminUser() {
      return this.auth && this.$authService && this.$authService.isAdminUser;
    },
    pageMenuConf: function () {
      return menuConf[this.$route.name]
    },
    groups: function () {
      if (this.pageMenuConf) {
        const {groups} = this.pageMenuConf;
        return groups;
      }

      console.error("No menu configuration has been defined for route", this.$route.path);
      return null;
    },
    menuMode: function (){
      const routeName = this.$route.name;
      const {menu} = menuConf[routeName] || { menu: 'expanded'};
      return menu;
    },
    isFooterVisible: function (){
      const { hideFooterInitially } = this.pageMenuConf || {};
      if (! hideFooterInitially) return true;
      return this.isWindowScrolled;
    },
  },
  beforeCreate() {
    this.$authService.loadFromStore();
    this.$store.commit('AUTH_INITIALISED_MT', this.$authService.getAuthState());
  },
  beforeUpdate() {
    this.redirectToLoginIfRequired();
  },
  methods: {
    redirectToLoginIfRequired() {
      if (this.$authService && !this.$authService.isSessionCurrent() && this.$route.meta.isSecure === true) {
        this.$appRouter.pushRoute('LOGIN');
      }
    },
    handleScroll(){
      this.isWindowScrolled = true;
    },
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
}
</script>

