<template>
  <div class="s-content__main large-8">
    <form v-on:submit.prevent>
      <fieldset>
        <p v-if="auth && auth.message" class="lead">{{auth.message}}</p>
        <div class="form-field">
          <input v-model="email" ref='email' placeholder="Your email" icon="envelope" type="email" class="h-full-width" />
        </div>
        <div v-if="auth && auth.loginCodeSent" class="form-field">
          <input v-model="loginCode" ref="loginCode" placeholder="Your login code" icon="lock" type="text" class="h-full-width"/>
        </div>
        <div v-if="auth && auth.loginCodeSent === true">
            <button @click="login" class="btn btn--primary btn-wide btn--large h-full-width">Login</button>
        </div>
        <div v-if="!auth || auth.loginCodeSent !== true">
          <button @click="requestLoginCode" class="btn btn--primary btn-wide btn--large h-full-width">Next</button>
        </div>
      </fieldset>
    </form>
  </div>
</template>

<script>
  export default {
    name: 'LoginForm',
    props:['auth'],
    data:function () {
      return {
        email: '',
        loginCode: '',
      }
    },
    beforeUpdate() {
    },
    methods: {
      requestLoginCode() {
        const {email} = this ;
        this.$emit('requestLoginCode', {
          email,
        });
      },
      login() {
        const {email, loginCode} = this ;
        this.$emit('login', {
          email,
          loginCode
        });
      },
    },
  }
</script>

<style scoped>

</style>