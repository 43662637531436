export const validateForm = (schema, $v) => {
  document.querySelector('form').classList.add('was-validated');
  Object.keys(schema).forEach(x => {
    const field = document.querySelector(`#${x}Input`);
    const customValidity = $v[x].$invalid ? 'invalid' : '';
    if (field) {
      field.setCustomValidity(customValidity);
    }
  });
  $v.$touch()
  if ($v.$invalid) {
    return false;
  } else {
    return true;
  }
}

export const clearForm = (schema, $v) => {
  document.querySelector('form').classList.remove('was-validated');
  Object.keys(schema).forEach(x => {
    const field = document.querySelector(`#${x}Input`);
    if (field) {
      field.setCustomValidity('valid');
    }
  });
  $v.$reset()
}