export default {
  mounted: function () {
    if (window.location.hash === "#add-comments") {
      this.$nextTick(function () {
        window.setTimeout(() => {
          this.scrollToHash(window.location.hash);
        }, 0);
      })
    }
  },
  methods: {
    scrollToHash(hash) {
      const node = document.querySelector(hash);
      if (node) {
        node.scrollIntoView(true);
      }
    }
  }
};
