import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
// import VueAnalytics from 'vue-ua';
import authService from './services/Auth';
import eventBus from './services/EventBus';
import "./mixins/MarkDown";
import mdService from './services/MarkDown';
import Vuelidate from 'vuelidate'
import ceTitle from './components/CeTitle';
import PageFooter from "@/components/PageFooter.vue";
import {router, routerService} from "./services/routerService";
/* import the fontawesome core */
import {library} from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

import {fas} from '@fortawesome/free-solid-svg-icons';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';

import './base.css';
import './main.css';

library.add(fas, fab, far)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('ce-title', ceTitle);
Vue.component('page-footer', PageFooter);

// Vue.use(VueAnalytics, {
//   appName: 'Prefactor.io', // Mandatory
//   appVersion: '1.0.0', // Mandatory
//   trackingId: 'G-TH7CSP4EW7', // Mandatory
//   debug: true, // Whether or not display console logs debugs (optional)
//   vueRouter: router, // Pass the router instance to automatically sync with router (optional)
//   ignoredViews: ['homepage'], // If router, you can exclude some views name (case insensitive) (optional)
//   trackPage: true, // Whether you want page changes to be recorded as pageviews (website) or screenviews (app), default: false
//   createOptions: { // Optional, Option when creating GA tracker, ref: https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference
//     siteSpeedSampleRate: 10,
//   },
//   // globalMetrics: [ // Optional
//   //   {metric: 1, value: 'MyMetricValue'},
//   //   {metric: 2, value: 'AnotherMetricValue'}
//   // ]
// })

Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(Vuelidate)

new Vue({
  router,
  appRouter: routerService,
  authGlobalMixin: authService,
  bus: eventBus,
  md: mdService,
  el: '#app',
  render: h => h(App),
});
