import {required, email} from 'vuelidate/lib/validators'
import {minLength, maxLength, url, length} from "./rules";

export default {
  required,
  minLength: minLength,
  maxLength: maxLength,
  url: url,
  length: length ,
  email,
};

