import VueRouter from "vue-router";
import {PATHS, routes} from "../routes";
import {Router} from "./Router";

export const router = new VueRouter({
    mode: 'history',
    base: __dirname,
    routes // short for `views: views`
});

export const routerService = Router.createInstance(router, PATHS);