import rulesMapping from './rulesMapping';
import messages from "./messages";

export class Validation {
  constructor(validators) {
    this.validators = validators ;
  }

  getValidation(schema) {
    if (typeof schema !== 'object') {
      throw "Invalid schema provided to validator";
    }

    const fieldNames = Object.keys(schema); // [foo, bar]
    const validationObj = fieldNames.reduce((ac, cur) => {
      const rules = schema[cur].rules; // { require: true, rule1: val1 }
      const ruleNames = Object.keys(rules); // [required, rule1]

      // returns {required: required, rule1: rule1(val1)}
      const validationRules = ruleNames.reduce((ac, cur) => {
        if (!this.validators[cur]) {
          throw `Invalid rule name provided: ${cur}.  Make sure the rule has been defined in validation rule mapping.`;
        }
        const params = rules[cur];
        if ( params && params !== true) {
          ac[cur] = this.validators[cur](params);
        } else if (params !== false ) {
          ac[cur] = this.validators[cur];
        }

        return ac;
      }, {});

      // returns { foo: {required: required, rule1: rule1} , bar ...}
      ac[cur] = validationRules;
      return ac;
    }, {});

    return validationObj;
  }

  getMessages(validatedRules, rules) {
    const keys = Object.keys(messages);
    const valRules = rules;
    const intplMsgs = keys.map(x => {
      if ( validatedRules[x] === false ) {
        const msg = typeof messages[x] == 'string' ? messages[x] : messages[x].msg,
          paramName = typeof messages[x] == 'string' ? null : messages[x].param,
          paramValue = paramName ? valRules[x] : null, // this.model.$params[x][paramName] : null,
          intplMsg = paramName ? msg.replace(`{{${paramName}}}`, paramValue) : msg ;

        return {rule: x , message: intplMsg};
      }
    }).filter(m => !!m);
    const oIntplMsgs = intplMsgs.reduce( (ac, cur) => {
      ac[cur.rule] = cur;
      return ac;
    } , {});

    return  oIntplMsgs;
  }
}


export default new Validation(rulesMapping);
