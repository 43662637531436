<template>
  <dialog id="shareDialog" :open="isShown">
    <header>
      <div class="close-icon">
        <a @click="closeModal">
          <font-awesome-icon :icon="['far', 'times-circle']" />
        </a>
      </div>
      <h3>Share Post</h3>
    </header>
    <div>
      <p class="h-full-width">
        <template v-if="! copied && ! error">
          Please click on the copy button below to copy the link of this article to share on your chosen platform.
        </template>
        <template v-if="!copied && error">
          There was an error copying to clipboard. This is usually to do with browser permissions. Please copy and paste
          the link below instead:

          <blockquote>{{ url }}</blockquote>
        </template>
        <template v-if="copied && ! error">
          Link copied. Thanks for sharing !
        </template>
      </p>

    </div>
    <footer>
      <button color="success" @click="copyToClipboard">
        <span v-if="!copied">Copy Link</span>
        <span v-if="copied">Copied</span>
      </button>
    </footer>
  </dialog>
</template>

<script>
import {setClipboard} from "../../services/clipboard";

export default {
  props: ['modalShown', 'closeModalCallback'],
  data: function () {
    return {
      copied: false,
      error: false,
      url: window.location.origin + window.location.pathname,
      dialogElem: document.getElementById("shareDialog"),
    }
  },
  methods: {
    closeModal: function () {
      this.closeModalCallback();
    },
    copyToClipboard: function () {
      setClipboard(this.url, () => {
        this.copied = true;
        window.setTimeout(() => {
          this.closeModalCallback()
        }, 1500);
      }, function () {
        this.error = true;
      }.bind(this));
    }
  },
  computed: {
    isShown: function () {
      return this.modalShown || false;
    }
  }
};
</script>
<style scoped lang="scss">

dialog[open] {
  opacity: 1;
  transform: scaleY(1);
}

@starting-style {
  dialog[open] {
    opacity: 0;
    transform: scaleY(0);
  }
}

dialog {
  transform: scaleY(0);
  transition: all 0.7s allow-discrete;
  z-index: 1;
  width: 600px;
  padding: 1em;
  border-radius: 3px;
  position: fixed;
  top: 40vh;
}

header {
  h3 {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 800px) {
  dialog {
    width: 90%;
  }
}

.close-icon {
  position: relative;
  margin-left: auto;
  margin-right: 0px;
  width: 20px;

  a {
    color: #3e3e3e;
  }
}
</style>