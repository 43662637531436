export default {
  required: 'This field is required',
  minLength: {
    param: 'min',
    msg: 'This field should be minimum of {{min}} characters long.'
  },
  maxLength: {
    param: 'max',
    msg: 'This field should be maximum of {{max}} characters long.'
  },
  url: 'This field should be a URL.',
  email: 'This field should be a valid email.',
  length: {
    param: 'length',
    msg: 'This field should be exactly {{length}} characters long.'
  } ,
};
