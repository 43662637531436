import axios from 'axios' ;

import {filterPostsByTagsAndCategory} from './transformers';
import authService from "@/services/Auth";

export class PostApi {
  constructor(baseUrl, authService) {
    this.authService = authService
    this.baseUrl = baseUrl;

    this.axiosInstance = axios.create({
      baseURL: baseUrl,
      headers: {
        'x-api-token': null
      }
    });

    this.axiosInstance.interceptors.request.use((config) => {
      if (this.authService.token) {
        config.headers['x-api-token'] = this.authService.token;
      }
      return config;
    });

    this._posts = [];
  }

  getAllPosts(published) {
    const url = published ? '/posts?published=1' : '/posts';
    return this.axiosInstance.get(url).then((posts) => {
      const {data} = posts;
      this._posts = data;
      return posts;
    });
  }

  getAllPublishedPostNames() {
    return this.axiosInstance.get('/published_posts/names');
  }

  getFeaturedPostsAndParameters() {
    return this.axiosInstance.get('/home');
  }

  getFeaturedPosts() {
    return this.axiosInstance.get('/featured/posts');
  }

  getParameters() {
    return this.axiosInstance.get('/parameters');
  }

  filterBy(posts, {tags, category}) {
    return filterPostsByTagsAndCategory(posts, {tags, category});
  }

  getPost(spec) {
    if ( spec.id ) {
      return this.axiosInstance.get(`/posts/${spec.id}`);
    }

    return this.axiosInstance.get(`/posts/${spec.slug}/published`);
  }

  addPost(post) {
    return this.axiosInstance.post('/posts', post);
  }

  updatePost(post) {
    return this.axiosInstance.put('/posts/' + post.id, post);
  }

  publishPost(postId) {
    return this.axiosInstance.post(`/posts/${postId}/publish/`);
  }

  highlightPost(postId) {
    return this.axiosInstance.post(`/posts/${postId}/highlight/`);
  }

  lowlightPost(postId) {
    return this.axiosInstance.post(`/posts/${postId}/lowlight/`);
  }

  featurePost(postId) {
    return this.axiosInstance.post(`/posts/${postId}/feature/`);
  }

  unfeaturePost(postId) {
    return this.axiosInstance.post(`/posts/${postId}/unfeature/`);
  }

  unpublishPost(postId) {
    return this.axiosInstance.post(`/posts/${postId}/unpublish`);
  }

  deletePost(postId) {
    return this.axiosInstance.delete(`/posts/${postId}`);
  }

  addComment(postId, commentPayload) {
    commentPayload.email = this.authService.email;
    return this.axiosInstance.post(`/posts/${postId}/comments`, commentPayload);
  }

  deleteComment(commentId) {
    return this.axiosInstance.delete(`/comments/${commentId}`);
  }

  likePost(postId) {
    return this.axiosInstance.post(`/posts/${postId}/like/`);
  }

  sharePost(postId) {
    return this.axiosInstance.post(`/posts/${postId}/share/`);
  }
}

export default new PostApi('/api', authService);
