var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEditing),expression:"isEditing"}]},[_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.checkForm.apply(null, arguments)}}},[(_vm.saveInProgress)?_c('div',{staticClass:"spinner-box"},[_vm._m(0)]):_vm._e(),(_vm.postSaved)?_c('div',{staticClass:"alert alert-success",attrs:{"role":"alert"}},[_c('span',[_vm._v(" Post saved successfully. ")])]):_vm._e(),(_vm.error && _vm.error.message)?_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[_c('span',[_vm._v(" An error occurred "+_vm._s(_vm.error.message))])]):_vm._e(),(_vm.imageUploadMessage)?_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[_c('span',[_vm._v(" An error occurred "+_vm._s(_vm.imageUploadMessage))])]):_vm._e(),_c('cc-input',_vm._b({},'cc-input',{type: 'checkbox', ..._vm.schema.hideFromListing , model: _vm.$v.hideFromListing},false)),_c('cc-input',_vm._b({},'cc-input',{type: 'text', ..._vm.schema.topic , model: _vm.$v.topic},false)),_c('cc-input',_vm._b({},'cc-input',{type: 'text', ..._vm.schema.tagsJoined , model: _vm.$v.tagsJoined},false)),_c('cc-input',_vm._b({},'cc-input',{type: 'select', ..._vm.schema.category , model: _vm.$v.category, options: _vm.schema.category.options},false)),_c('image-upload',_vm._b({attrs:{"imageType":"postBanner"},on:{"on-insert":_vm.insertImage,"on-media-removed":_vm.removeMedia}},'image-upload',{
        imageUrl: _vm.imageUrl,
        uploadStatus:_vm.imageUploadStatus,
        imageUploadMessage: _vm.imageUploadMessage,
        id: _vm.id,
        mediaFiles: _vm.currentPost.mediaFiles,
      },false)),_c('div',{staticClass:"image-selector"},[_c('label',[_vm._v(" Main Image ")]),_c('div',{staticClass:"image-selector__container"},[_c('img',{attrs:{"src":_vm.bannerImagePath}}),_c('cc-input',_vm._b({},'cc-input',{type: 'multisizeImageSelect', options: {list: _vm.currentPost.mediaFiles, label: 'url', value: 'index'}, ..._vm.schema.bannerImageIndex , model: _vm.$v.bannerImageIndex, icon:'tag'},false))],1)]),_c('cc-input',_vm._b({},'cc-input',{type: 'textarea', ..._vm.schema.extract , model: _vm.$v.extract, rows: 5},false)),_c('md-textarea',_vm._b({},'md-textarea',{..._vm.schema.content, content: _vm.content, model: _vm.$v.content, rows: 20, cols: 120},false)),_c('div',{staticClass:"row"},[_c('cc-input',_vm._b({staticClass:"large-half"},'cc-input',{type: 'select', ..._vm.schema.previousLink , model: _vm.$v.previousLink, options: _vm.articleLinkOptions},false)),_c('cc-input',_vm._b({staticClass:"large-half"},'cc-input',{type: 'select', ..._vm.schema.nextLink , model: _vm.$v.nextLink, options: _vm.articleLinkOptions},false))],1),_c('div',{staticClass:"row"},[_c('button',{staticClass:"btn--large btn--primary",attrs:{"id":"submitBtn","disabled":_vm.saveInProgress},on:{"click":_vm.checkForm}},[(_vm.saveInProgress)?_c('div',[_c('span',{staticClass:"spinner-grow spinner-grow-sm",attrs:{"role":"status","aria-hidden":"true"}}),_vm._v(" In Progress... ")]):_vm._e(),(!_vm.saveInProgress)?_c('div',[_vm._v(" Save ")]):_vm._e()]),_c('button',{staticClass:"btn--large btn--stroke",attrs:{"type":"cancel"},on:{"click":_vm.viewAllPosts}},[_vm._v("Cancel ")])])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"spinner-border",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])
}]

export { render, staticRenderFns }