import axios from 'axios' ;
import authService from "@/services/Auth";

export class ImageUploadApi {
  constructor(baseUrl, authService) {
    this.authService = authService
    this.baseUrl = baseUrl;

    this.axiosInstance = axios.create({
      baseURL: baseUrl,
      headers: {
        'x-api-token': null,
      }
    });

    this.axiosInstance.interceptors.request.use((config) => {
      if (this.authService.token) {
        config.headers['x-api-token'] = this.authService.token;
      }
      return config;
    });
  }

  _b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {type: contentType});
  }

  _getBlob(b64Data) {
    const block = b64Data.split(";");
    const mimeType = block[0].split(":")[1];
    const data = block[1].split(",")[1];
    const blob = this._b64toBlob(data, mimeType);
    return {
      blob,
      mimeType,
    }
  }

  uploadImage({image, imageType, name, id}){
    const endPoints = {
      'postBanner': 'post-banner'
    };

    const {mimeType, blob} = this._getBlob(image) ;
    const formData = new FormData();
    formData.append('type', imageType);
    formData.append('mimeType', mimeType);
    formData.append('image', blob , name);
    formData.append('id', id);
    return this.axiosInstance.post(`/upload/${endPoints[imageType]}`, formData);
  }

}

export default new ImageUploadApi('/images', authService);
