<template>
  <div class="row row-x-center">
    <share-modal v-if="modalShown" v-bind:modalShown="modalShown" v-bind:closeModalCallback="closeShareModal"/>
    <div class="column large-10 medium-12 small-12">
      <div class="comments-wrap">
        <post-metrics v-if="postId" v-bind:show-comments-callback="showComments"
                      v-bind:showShareModal="this.showShareModal"
                      v-bind:noOfComments="count" v-bind:post-id="postId"/>
        <div v-if="count > 0 && this.commentsShown" id="comments">
          <h3>{{ count }} Comments</h3>

          <!-- START commentlist -->
          <ol class="commentlist">
            <post-comment :key="comment.id" v-for="comment in tree" :count="count" :comment="comment" :post-id="postId"
                          :replyToCommentId="replyToCommentId" :depth="1" :setReplyToCommentId="setReplyToCommentId"/>
          </ol>
          <!-- END commentlist -->
        </div> <!-- end comments -->
        <a id="add-comments"></a>
        <add-comment v-if="this.commentsShown && !replyToCommentId" :postId="postId"/>
      </div> <!-- end comments-wrap -->
    </div>
  </div>
</template>

<script>

import AddComment from "./AddComment.vue"
import PostComment from "./PostComment.vue";
import PostMetrics from "@/components/post/PostMetrics.vue";
import ShareModal from "@/components/modals/ShareModal.vue";
import scrollWindowMixin from "@/mixins/scrollToComments";

export default {
  name: "PostComments",
  props: ['postId', 'tree', 'count'],
  mixins: [scrollWindowMixin],
  data() {
    return {
      replyToCommentId: null,
      commentsShown: window.location.hash.indexOf('add-comments') >= 0,
      modalShown: false,
    }
  },
  methods: {
    setReplyToCommentId(commentId) {
      this.replyToCommentId = this.replyToCommentId === commentId ? null : commentId;
    },
    showComments() {
      this.commentsShown = !this.commentsShown;
    },
    showShareModal() {
      this.modalShown = true;
    },
    closeShareModal() {
      this.modalShown = false;
    },
  },
  components: {
    ShareModal,
    PostMetrics,
    AddComment,
    PostComment
  }
}
</script>

<style lang="scss" scoped>
</style>
