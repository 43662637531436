export default {
  comment: {
    id: 'comment',
    label: 'Comment',
    default: '',
    rows: 5,
    rules: {
      required: true,
      minLength: 10,
    }
  }
};
