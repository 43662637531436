import Vue from "vue";

Vue.mixin( {
    beforeCreate() {
      const options = this.$options;
      if ( options.md )
        this.$md = options.md;
      else if ( options.parent && options.parent.$md )
        this.$md = options.parent.$md;
    },
});
