<script>

import moment from "../../services/date";
import AddComment from "./AddComment.vue"

export default {
  name: "Comment",
  props: ['comment', 'postId', 'replyToCommentId', 'depth', 'setReplyToCommentId', 'count'],
  computed: {
    content:  function () {
      return this.comment.isDeleted === true ? 'This comment has been deleted' : this.comment.content;
    },
  },
  methods: {
    deleteComment(ev, commentId) {
      ev.preventDefault();
      this.$store.dispatch('DELETE_COMMENT', commentId);
    },
    replyToComment(ev, commentId) {
      ev.preventDefault();
      this.setReplyToCommentId(commentId);
    },
    likeComment() {
    },
    formatDate(date) {
      return moment(date).format();
    },
    convertNL(content) {
      return content.split('\n');
    },
    isAdminUserOrOwner(userId) {
      return this.$authService.isAdminUserOrOwner(userId);
    },
    getCssClass(comment, depth) {
      const altThreadClass = this.isTopOfThread(comment) ? 'thread-alt' : '';
      return `${altThreadClass} depth-${depth || '1'} comment`
    },
    isTopOfThread() {
      return this.comment.children && this.comment.children.length > 0 && this.depth == 1;
    }
  },
  components: {
    AddComment,
  }
}

</script>

<template>
  <li :class="getCssClass(comment, depth)">
    <div v-if="comment.avatarSrc" class="comment__avatar">
      <img class="avatar" :src="comment.avatarSrc" alt="" width="50" height="50">
    </div>
    <div class="comment__content">
      <div class="comment__info">
        <div class="comment__author">{{ comment.authorDisplayName }}</div>

        <div class="comment__meta">
          <div class="comment__time">{{ formatDate(comment.created) }}</div>
          <div class="comment__reply">
            <a class="comment-reply-link" href="#0" v-on:click="(ev) => replyToComment(ev, comment.id)">
              Reply
            </a>
            <a v-if="isAdminUserOrOwner(comment.authorId)" v-on:click="(ev) => deleteComment(ev, comment.id)">
              Delete
              <!--                    <font-awesome-icon :icon="['far', 'trash-alt']" />-->
            </a>
          </div>
        </div>
      </div>

      <div class="comment__text">
        <p v-for="(paragraph, index) in convertNL(content)" :key="index">
          {{ paragraph }}
        </p>
      </div>
    </div>
    {{ /** a little hack to get around the issue with vuex not updating the children */}}
    <span style="display: none">{{ count }}</span>
    <ul v-if="comment.children && comment.children.length >= 0" class="children">
      <comment :key="child.id" v-for="child in comment.children" :postId="postId" :comment="child" :depth="depth + 1"
               :reply-to-comment-id="replyToCommentId" :count="count" :setReplyToCommentId="setReplyToCommentId"/>
    </ul>
    <add-comment v-if="replyToCommentId === comment.id" :reply-to-comment-id="replyToCommentId" :postId="postId"/>
  </li>
</template>

<style scoped lang="scss">

</style>