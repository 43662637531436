import Home from "./views/Home";
import LoginRoute from "./views/Login";
import MyPostsRoute from "./views/MyPosts";
import MyPostRoute from "./views/MyPost";
import CreatePostRoute from "./views/CreatePost";
import PostRoute from "./views/Post";
import EditPostRoute from "./views/EditPost";
import ArticlesRoute from "./views/Articles";
import AboutMe from "./views/AboutMe";
import ContactUs from "./views/ContactUs.vue";
import PageNotFound from "./views/PageNotFound";
import ProcessPassportLoginRoute from "./views/ProcessPassportLogin.vue";

export const PATHS = {
  HOME: '/',
  LOGIN: '/login',
  ProcessPassportLogin: '/logged-in',

  MY_POST: '/my-posts/:id',
  EDIT_POST: '/my-posts/:id/edit',
  CREATE_POST: '/my-posts/create',
  MY_POSTS: '/my-posts',

  ARTICLES: '/articles',
  VIEW_POST: '/articles/:slug',
  ARTICLES_WITH_TAG: '/articles/?tag=:tag',

  ABOUT: '/about',
  CONTACT_US: '/contact-us',
};

/**
 * order of views is important
 */
export const routes = [
  {name: 'Home', path: PATHS.HOME, component: Home, meta: {title: 'Home', description: 'View a variety of posts and topics in here.'}},
  {name: 'AboutMe', path: PATHS.ABOUT, component: AboutMe, meta: {title: 'About', description: 'A few words about me.'}},
  {name: 'ContactUs', path: PATHS.CONTACT_US, component: ContactUs, meta: {title: 'Contact us', description: 'Contact us'}},
  {name: 'Login', path: PATHS.LOGIN, component: LoginRoute, meta:{title: 'Login', description: 'Login to create or edit your content.'}},
  {name: 'PassportLoggedIn', path: PATHS.ProcessPassportLogin, component: ProcessPassportLoginRoute, meta:{title: 'Processing your login', description: 'Processing your login.'}},
  {name: 'MyPosts', path: PATHS.MY_POSTS, component: MyPostsRoute, meta: {title:'My drafts', description: 'View my posts.', isSecure: true}},
  {name: 'ViewPost', path: PATHS.VIEW_POST, component: PostRoute, meta:{}},
  {name: 'EditPost', path: PATHS.EDIT_POST, component: EditPostRoute, meta: {isSecure: true, title: 'Edit Post'}},
  {name: 'CreatePost', path: PATHS.CREATE_POST, component: CreatePostRoute, meta: {isSecure: true, title: 'Create content', description: 'Create new content'}},
  {name: 'MyPost', path: PATHS.MY_POST, component: MyPostRoute, meta: {isSecure: true, title: 'My Post'}},
  {name: 'Articles', path: PATHS.ARTICLES, component: ArticlesRoute, meta: {title: 'Articles of my recent posts', description: 'View a timeline of my recent posts.'}},
  {name: 'ArticlesWithTag', path: PATHS.ARTICLES_WITH_TAG, component: ArticlesRoute, meta: {title: 'Articles of my recent posts', description: 'View a timeline of my recent posts.'}},
  {
    name: '404',
    path: '*',
    component: PageNotFound,
    meta: { layout: 'none', title: 'Page Not Found - 404', isSecure: false }
  }
];

const publicGroups = ['home', 'articles', 'about', 'contact-us', 'login'];
const contentEditorGroups = ['home', 'articles','my-posts'];
const postGroups = [ 'create', 'edit', 'publish',];

export const menuConf =
  {
    'Home': {
      groups: [...publicGroups, ...contentEditorGroups],
      menu: 'expanded',
      hideFooterInitially: true,
    },
    'AboutMe': {
      groups: [...publicGroups, ...contentEditorGroups],
      menu: 'collapsed',
      hideFooterInitially: true,
    },
    'Login': {
      groups: publicGroups,
      menu: 'expanded',
    },
    'MyPosts': {
      groups: [...publicGroups, ...contentEditorGroups, ...postGroups],
      menu: 'expanded',
    },
    'MyPost': {
      groups: [...publicGroups, ...contentEditorGroups, ...postGroups],
      menu: 'collapsed',
    },
    'Articles': {
      groups: [...publicGroups, ...contentEditorGroups],
      menu: 'expanded',
      hideFooterInitially: true,
    }
    ,'ArticlesWithTag': {
      groups: [...publicGroups, ...contentEditorGroups],
      menu: 'expanded',
    },
    'CreatePost': {
      groups: [...publicGroups, ...contentEditorGroups, ...postGroups],
      menu: 'collapsed',
    },
    'EditPost': {
      groups: [...publicGroups, ...contentEditorGroups, ...postGroups],
      menu: 'collapsed',
    },
    'ViewPost': {
      groups: [...publicGroups, ...contentEditorGroups, ...postGroups],
      menu: 'collapsed',
      hideFooterInitially: true,
    },
    'ContactUs': {
      groups: [...publicGroups],
      menu: 'collapsed',
    },
    '404': {
      groups: [...publicGroups],
      menu: 'expanded',
    }
  };
