<template>
  <div>
    <ce-title fromRoute/>
    <h1> Create Post </h1>
    <editor v-bind="{post, imageUploadStatus, imageUrl, requestStatus}" v-on:save-post="createPost"></editor>
  </div>
</template>

<script>
import Editor from '../components/forms/Editor/Editor';
import uuidv4 from 'uuid/v4';
import {mapState} from "vuex";

export default {
  name: 'create-post-route',
  data: function () {
    return {
      post:
          {
            id: uuidv4(),
            topic: '',
            author: this.$authService.userId,
            created: new Date(),
            content: '',
            tags: [],
          }
    }
  },
  computed: {
    ...mapState([
      'imageUrl',
      'imageUploadStatus',
      'imageUploadMessage',
      'requestStatus',
    ])
  },
  components: {
    Editor,
  },
  methods: {
    createPost(post) {
      this.$store.dispatch('CREATE_POST', {...post})
    },
  },
  beforeCreate: function () {
    this.$store.commit('INIT_POST_MT');
  },
}
</script>
