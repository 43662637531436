<template>
  <div>
    <ce-title fromRoute/>
    <ce-filters v-bind:filters="filters" v-on:toggle-filter="toggleFilterTag"></ce-filters>
    <article-listing :posts="posts" type="draft"></article-listing>
  </div>
</template>


<script>
import {mapState} from "vuex";
import CeFilters from "../components/CeFilter";
import ArticleListing from "@/components/post/ArticleListing.vue";

export default {
  name: 'posts-route',
  computed: {
    ...mapState([
      'posts',
      'auth',
      'filters',
    ]),
    isLoggedIn() {
      return this.$authService && this.$authService.isSessionCurrent()
    },
  },
  components: {
    ArticleListing,
    CeFilters,
  },
  methods: {
    toggleFilterTag(tag) {
      this.$store.commit('TOGGLE_FILTER_TAG', tag);
      this.$store.dispatch('FILTER_POSTS');
    }
  },
  // watch: {
  //   requireLogin: function () {
  //     this.$store.dispatch('LOAD_POSTS');
  //   }
  // },
  beforeCreate: function () {
    this.$store.dispatch('LOAD_POSTS');
  },
}
</script>
