import axios from 'axios' ;
import authService from "@/services/Auth";

export class AuthApi {
  constructor(baseUrl, authService) {
    this.baseUrl = baseUrl;
    this.authService = authService ;
  }

  requestLoginCode(email) {
    return axios.post(this.baseUrl + '/initiate', {email}).then((resp) => {
      this.authService.init(resp.data) ;
      this.authService.storeAuthState();
      return resp ;
    });
  }
  login(email, loginCode) {
    return axios.post(this.baseUrl + '/login', {email, loginCode}).then((resp) => {
      this.authService.init(resp.data) ;
      this.authService.storeAuthState();
      return resp ;
    });
  }

  register(email, password){
    return axios.post(this.baseUrl + '/register' , {email, password});
  }

  logout(){
    return axios.post(this.baseUrl + '/logout', {}, { headers: {'x-api-token':this.authService.token}})
      .then( () => {
        this.authService.init({});
        this.authService.clearAuthState();
      }
    );
  }
}

export default new AuthApi('/auth', authService);
