<template>
    <div>
        <table>
            <head>
                <tr>
                    <th>#</th>
                    <th>Mime Type</th>
                    <th>Media </th>
                    <th></th>
                </tr>
            </head>
            <body>
                <tr v-for="(media, idx) in list" :key="idx">
                    <td>
                      #{{idx}} : {{media.mimeType}}
                      <img v-if="media" v-bind:src="media.sm" width="200" />
                    </td>
                    <td>
                        <button color="success" @click="onInserted(media.lg)">Insert</button>
                        <button class="btn btn-grey" @click="(ev) => onRemoveClicked(ev, media, idx)">Remove</button>
                    </td>
                </tr>
            </body>
        </table>
    </div>
</template>

<script>
  export default {
    name: 'MediaFiles',
    props: ['list'],
    methods: {
      onInserted(url) {
        this.$emit('on-insert',url);
      },
      onRemoveClicked(ev, media, idx) {
        ev.preventDefault();
        this.$emit('on-media-removed', media, idx);
      },
      uploadImage() {
      }
    }
  }
</script>

<style scoped>

</style>