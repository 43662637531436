<template>
    <form v-on:submit.prevent>
      <h1>Processing your sign in ...</h1>
    </form>
</template>

<script>
  export default {
    name: 'LoggedInWithSocial',
    props:['auth'],
    beforeCreate() {
      const { t: token, e: expiry, email, displayName, userRoles, issuer, userId } = this.$route.query;
      this.$store.dispatch('SOCIAL_MEDIA_LOGGED_IN', {token, expiry, email, displayName, userRoles, issuer, userId });
    },
  }
</script>

<style scoped>

</style>