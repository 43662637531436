<script>
  import ArticleItem from "./ArticleItem.vue";
  import intersectionObservableMixin from "@/mixins/intersectionObservable";

  export default {
    name: "ArticleListing",
    props: ['posts', 'type'],
    mixins: [intersectionObservableMixin],
    components: {
      ArticleItem,
    },
  }
</script>

<template>
  <div class="home__container intersection-pane">
    <div class="row" :key="post.id" v-for="post in posts" >
      <article-item :post="post" :observer="observer" :type="type" />
    </div>
  </div>
</template>
