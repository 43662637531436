import Vue from 'vue';

export class Auth  {
  constructor() {
  }

  get USER_ROLES(){
    return {
      ADMIN: 'ADMIN',
      COMMENTATOR: 'COMMENTATOR',
      CONTENT_CREATOR: 'CONTENT_CREATOR',
    }
  }

  set fromUrl(url) {
    window.localStorage.setItem('fromUrl', JSON.stringify(url)) ;
  }

  get fromUrl() {
    return JSON.parse(window.localStorage.getItem('fromUrl'));
  }

  init(authState) {
    const {email, token, expiry, userRoles, displayName, issuer, userId} = authState;
    this._userRoles = userRoles;
    this._token = token;
    this._expiry = expiry;
    this._email = email;
    this._displayName = displayName;
    this._issuer = issuer;
    this._userId = userId;
  }

  loadFromStore() {
    const storedAuth = window.localStorage.getItem('AUTH');
    const auth = storedAuth && JSON.parse(storedAuth) ? JSON.parse(storedAuth) : null;
    if (auth) {
      this.init(auth);
    }
  }

  getAuthState() {
    return {
      userRoles : this.userRoles,
      isAdmin : this.isAdminUser,
      isSocialUser : this.isSocialUser,
      isContentCreator : this.isContentCreator,
      issuer : this._issuer,
      userId : this._userId,
      displayName : this._displayName,
      token: this.token,
      expiry: this.expiry,
    };
  }
  storeAuthState() {
    window.localStorage.setItem('AUTH', JSON.stringify(this.getAuthState()));
  }

  clearAuthState(){
    window.localStorage.removeItem('AUTH') ;
  }

  get isAdminUser() {
    return this._userRoles && this._userRoles.indexOf(this.USER_ROLES.ADMIN) >= 0;
  }

  get isSocialUser() {
    return this._userRoles && this._userRoles.indexOf(this.USER_ROLES.COMMENTATOR) >= 0;
  }

  get isContentCreator() {
    return this._userRoles && this._userRoles.indexOf(this.USER_ROLES.CONTENT_CREATOR) >= 0;
  }

  isSessionCurrent() {
    const currentTs = (new Date()).getTime() ;

    if (this.token && this.expiry > currentTs) {
      return true;
    }

    return false;
  }

  get issuer() {
    return this._issuer;
  }

  get token() {
    return this._token;
  }

  get expiry() {
    return this._expiry;
  }

  get displayName() {
    console.log(this._displayName);
    return this._displayName || 'Guest';
  }

  get userRoles() {
    return this._userRoles;
  }

  get email() {
    return this._email;
  }

  get userId() {
    return this._userId;
  }

  isAdminUserOrOwner(userId) {
    return this.isAdminUser || this._userId === userId;
  }
}

Vue.mixin( {
  beforeCreate() {
    const options = this.$options;
    if ( options.authGlobalMixin )
       this.$authService = options.authGlobalMixin;
    else if ( options.parent && options.parent.$authService )
      this.$authService = options.parent.$authService;
  }
});

export default new Auth() ;