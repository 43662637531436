<template>
  <div>
    <ce-title dynamic-title="Home" dynamic-description="Prefactor.io covers topics on software architecture, team building, digital transformations and technical direction of large scale developments."/>
    <div class="row">
      <div id="main" class="s-content__main large-8 column main-featured-posts">
        <featured-posts v-if="featuredPosts" v-bind="{featuredPosts}" class="home__featured-posts"></featured-posts>
      </div> <!-- end main -->
      <sidebar :categories="categories" :tags="tags" :featured-posts="featuredPosts" :observer="observer" />
    </div> <!-- end row -->
  </div>
</template>

<script>
import {mapState} from "vuex";
import FeaturedPosts from "../components/post/FeaturedPosts";
import Sidebar from "@/components/Sidebar.vue";
import intersectionObservableMixin from "@/mixins/intersectionObservable";

export default {
  name: 'Home',
  components: {
    FeaturedPosts,
    Sidebar,
  },
  mixins: [intersectionObservableMixin],
  computed: {
    ...mapState([
      'auth',
      'filters',
      'featuredPosts',
      'tags',
      'categories',
    ]),
    isLoggedIn() {
      return this.$authService && this.$authService.isSessionCurrent()
    },
  },
  beforeCreate: function () {
    this.$store.dispatch('LOAD_FEATURES_AND_PARAMETERS');
  },
}
</script>
<style lang="css">
 .main-featured-posts {
   min-height: 600px;
 }
</style>