<template>
  <div>
    <ce-title v-if="currentPost" :dynamic-title="pageTitle" :dynamic-description="pageDescription"/>
    <post v-if="currentPost && currentPost.id" v-bind="{post:currentPost}"></post>
    <page-not-found v-if="isPostNotFound" title="404 - Post Not Found">
      <p>
        The post you are looking for was not found.  It may have been moved or deleted. Please navigate to <router-link to="/my-posts">Drafts page</router-link>
        and try to access it from there.
      </p>
      <p>
        If that doesn't work, please contact us through our <router-link to="/contact-us">contact us</router-link> page.
      </p>
      <p>
        We appologise for the inconvenience.
      </p>
    </page-not-found>
  </div>
</template>


<script>
import Post from '../components/Post';
import postApi from "@/services/PostApi";
import PageNotFound from "@/components/PageNotFound.vue";
import {mapState} from "vuex";

export default {
  name: 'my-post-route',
  data: function (){
    return {
      currentPost: null
    }
  },
  computed: {
    ...mapState([
      'error',
    ]),
    isPostNotFound: function () {
      return this.error && this.error.status === 404 && this.error.spec.slug === this.$route.params.slug;
    },
    pageTitle: function () {
      if (this.currentPost)
        return this.currentPost.topic;

      return 'My Post';
    },
    pageDescription: function () {
      if (this.currentPost)
        return this.currentPost.extract;

      return null;
    }
  },
  components: {
    PageNotFound,
    Post,
  },
  beforeCreate: function () {
    const {id} = this.$route.params;
    this.$store.commit('NETWORK_REQUEST_IN_PROGRESS');
    postApi.getPost({id}).then( ({data}) => {
      this.currentPost = data;
      this.$store.commit('POST_LOADED_MT', data);
    }).catch( ({response}) => {
      this.currentPost = null;
      this.$store.commit('POST_NOT_FOUND_MT', {response, spec: {id}});
    });
  },
}
</script>
