<template>
    <div v-if="!isLoggedInWithOAuth">
      <div class="message-slot"><slot></slot></div>
      <a class="social-login social-login--first" href="/auth/login/federated/google" v-on:click="(event) => { loginWith(event, '/auth/login/federated/google') }"><img class="social-login-icon--google" v-bind:src="googleIcon" alt="Google Logo"/>
      </a>
      <a class="social-login" href="/auth/login/federated/github" v-on:click="(event) => { loginWith(event, '/auth/login/federated/github') }"><img class="social-login-icon--github" v-bind:src="githubIcon" alt="Github Logo"/>
      </a>
      <a class="social-login" href="/auth/login/federated/linkedin" v-on:click="(event) => { loginWith(event, '/auth/login/federated/linkedin') }"><img class="social-login-icon--linkedin" v-bind:src="linkedinIcon" alt="Linked In Logo"/>
      </a>
    </div>
    <div v-else>
      <div class="message-slot"><slot></slot></div>
      <div class="sm-logo"><img class="social-login-icon" v-bind:class="issuerLogoClass" v-bind:src="issuerLogo" alt="Logo"/></div>
      <div class="author-name"> {{displayName}}</div>
    </div>
</template>

<script>
  import googleIcon from "../../assets/img/social/google-icon.svg";
  import linkedinIcon from "../../assets/img/social/linked-in-icon.png";
  import githubIcon from "../../assets/img/social/github-icon.svg";

  export default {
    name: 'LoginWithSocial',
    props:['auth', 'isLoggedInWithOAuth', 'displayName', 'issuer'],
    data:function () {
      return {
        googleIcon,
        linkedinIcon,
        githubIcon,
      }
    },
    computed: {
      issuerLogo() {
        const mapping = {
          google: googleIcon,
          linkedin: linkedinIcon,
          github: githubIcon,
        }

        return mapping[this.issuer];
      },
      issuerLogoClass() {
        return `social-login-icon--${this.issuer}`
      }
    },
    methods: {
      loginWith(event, url) {
        event.preventDefault();
        this.$store.dispatch('LOGIN_WITH_SOCIAL_MEDIA', { redirect: url, currentUrl: window.location.pathname + '#add-comments'});
      },
    },
  }
</script>

<style scoped lang="scss">
img.social-login-icon--google {
  height: 44px;
}

img.social-login-icon--github {
  height: 42px;
}

img.social-login-icon--linkedin {
  height: 44px;
  padding: 2px;
}


 a.social-login {
   margin-left: 1rem;

   &--first {
     margin-left: 0;
   }

 }

 div.sm-logo {
   float: left;
 }

 div.author-name {
   padding-top: 5px;
 }

 .message-slot {
   margin-top: 1rem;
 }
</style>