import Vue from "vue";

export class Router {
  constructor($router, routes) {
    this.$router = $router;
    this.routes = routes;
  }

  static createInstance($router, routes) {
    return new Router($router, routes);
  }

  getRoute(name, params) {
    if (!this.routes[name]) {
      throw `We searched hard, but could not find the route you are looking for "${name}"` ;
    }

    let path = this.routes[name];

    if (params) {
      Object.keys(params).forEach(p => {
        if (path.indexOf(`:${p}`) < 0) {
          throw `Unexpected parameter "${p}" in route.`;
        }
        path = path.replace(`:${p}`, params[p])
      });
    }

    return path;
  }

  pushRoute(name, ...params) {
    this.$router.push(this.getRoute(name, ...params));
  }

  replaceRoute(name, ...params) {
    this.$router.replace(this.getRoute(name, ...params));
  }

  isCurrentOrChildPage(name, ...params) {
    if (this.getRoute(name, ...params) === '/') {
      return  window.location.pathname  === '/';
    }
    return window.location.pathname.indexOf(this.getRoute(name, ...params)) >= 0;
  }
}

Vue.mixin({
  beforeCreate() {
    const options = this.$options;
    if (options.appRouter)
      this.$appRouter = options.appRouter;
    else if (options.parent && options.parent.$appRouter)
      this.$appRouter = options.parent.$appRouter;
  }
});

