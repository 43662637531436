<template>
  <div>
    <ce-title fromRoute/>
    <h1 class="h-remove-top"> Contact us </h1>
    <login-with-social v-bind:isLoggedInWithOAuth="isSocialUser" v-bind:issuer="issuer"
                       v-bind:displayName="userDisplayName">
      Login with your social media accounts to contact us.
    </login-with-social>
    <contact-us-form v-if="isSocialUser" v-bind:user-email="userEmail"/>
  </div>
</template>

<script>
import ContactUsForm from "../components/forms/ContactUs/ContactUsForm.vue";
import LoginWithSocial from "../components/social/LoginWithSocial.vue";

export default {
  name: 'ContactUs',
  computed: {
    isLoggedIn() {
      return this.$authService && this.$authService.isSessionCurrent();
    },
    isSocialUser() {
      return this.isLoggedIn && this.$authService.isSocialUser;
    },
    userDisplayName() {
      return this.$authService.displayName;
    },
    userEmail() {
      return this.$authService.email;
    },
    issuer() {
      return this.$authService.issuer;
    }
  },
  components: {
    LoginWithSocial,
    ContactUsForm,
  },
}
</script>

<style lang="scss">
</style>
