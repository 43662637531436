<template>
  <div>
    <h1 class="h-remove-top"> Login </h1>
    <ce-title fromRoute is-private="true"/>
    <login-form v-on:login="login" v-on:requestLoginCode="requestLoginCode" v-bind:auth="auth"></login-form>
  </div>
</template>

<script>
import LoginForm from '../components/forms/LoginForm';
import {mapActions, mapState} from 'vuex';

export default {
  name: 'login-route',
  computed: mapState([
    'auth',
  ]),
  methods: mapActions({
    login: 'LOGIN',
    requestLoginCode: 'REQUEST_LOGIN_CODE',
  }),
  components: {
    LoginForm,
    // mdbContainer,
  },
  beforeUpdate() {
    if (this.$authService.isSessionCurrent()) {
      this.$appRouter.pushRoute('MY_POSTS');
    }
  }
}
</script>
