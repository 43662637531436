import MarkdownIt from "markdown-it";
import markdownItAttrs from "markdown-it-attrs";

const md = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true
  }
);

md.use(markdownItAttrs, {
  leftDelimiter: '{',
  rightDelimiter: '}',
  allowedAttributes: []
});

export default md;