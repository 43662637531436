<template>
  <div>
    <ce-title fromRoute is-private="true"/>
    <h1> Processing your login </h1>
    <div>
      <logged-in-with-social></logged-in-with-social>
    </div>
  </div>
</template>

<script>
import LoggedInWithSocial from '../components/social/LoggedInWithSocial.vue';
import {mapActions, mapState} from 'vuex';

export default {
  name: 'process-passport-login-route',
  computed: mapState([
    'auth',
  ]),
  methods: mapActions({
    login: 'LOGIN',
    requestLoginCode: 'REQUEST_LOGIN_CODE',
  }),
  components: {
    LoggedInWithSocial,
  },
  beforeUpdate() {
    if (this.$authService.isSessionCurrent()) {
      this.$appRouter.pushRoute('MY_POSTS');
    }
  }
}
</script>
