<script>
import {mapState} from "vuex";
import {NETWORK_REQUEST} from "@/store/initialise";

export default {
  name: 'LoadingSpinner',
  computed: {
    ...mapState([
      'requestStatus',
    ]),
    displayProgressBar:function (){
      return this.requestStatus === NETWORK_REQUEST.IN_PROGRESS;
    },
  },
}
</script>

<template>
  <!-- preloader
     ================================================== -->
  <div id="preloader" :class="{'is-hidden': !displayProgressBar}">
    <div id="loader" class="dots-fade" :class="{'is-hidden': !displayProgressBar}">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.is-hidden{
  display: none;
}
</style>