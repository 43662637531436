<template>
  <div class="post-container">
    <ce-title v-if="currentPost" :dynamic-title="pageTitle" :dynamic-description="pageDescription" :lcp-href="lcpHref"
              :lcp-mime-type="lcpMimeType"/>
    <post v-if="currentPost && currentPost.id" v-bind="{post:currentPost}"></post>
    <post-comments v-if="currentPost && currentPostComments" :tree="currentPostComments.tree"
                   :count="currentPostComments.count" :post-id="currentPost.id" max-comments="3"/>
    <page-not-found v-if="isPostNotFound" title="404 - Post Not Found">
      <p>
        The post you are looking for was not found. It may have been moved or deleted. Please navigate to
        <router-link to="/articles">Articles page</router-link>
        and try to access it from there.
      </p>
      <p>
        If that doesn't work, please contact us through our
        <router-link to="/contact-us">contact us</router-link>
        page.
      </p>
      <p>
        We appologise for the inconvenience.
      </p>
    </page-not-found>
  </div>
</template>

<script>
import Post from '../components/Post';
import store from '../store/initialise';
import {mapState} from "vuex";
import PageNotFound from "@/components/PageNotFound.vue";
import {PostComments} from "@/components/post";
import postApi from "@/services/PostApi";

export default {
  name: 'post-route',
  store,
  data: function () {
    return {
      currentPost: null
    }
  },
  computed: {
    ...mapState([
      'error',
      'currentPostComments',
      'requestStatus',
    ]),
    isPostNotFound: function () {
      return this.error && this.error.status === 404 && this.error.spec.slug === this.$route.params.slug;
    },
    pageTitle: function () {
      if (this.currentPost)
        return this.currentPost.topic;

      return null;
    },
    slug: function () {
      const {slug} = this.$route.params;
      return slug;
    },
    pageDescription: function () {
      if (this.currentPost)
        return this.currentPost.extract;

      return null;
    },
    lcpHref: function () {
      if (this.currentPost && this.currentPost.bannerImagePath) {
        const {sm, md, lg} = this.currentPost.bannerImagePath;
        if (window && window.innerWidth) {
          if (window.innerWidth <= 250)
            return sm;
          else if (window.innerWidth <= 500) {
            return md;
          }
        }
        return lg;
      }
      return false;
    },
    lcpMimeType: function () {
      if (this.currentPost && this.currentPost.bannerImagePath) {
        return this.currentPost.bannerImagePath.mimeType;
      }
      return false;
    }
  },
  components: {
    PostComments,
    PageNotFound,
    Post,
  },
  beforeCreate: function () {
    const {slug} = this.$route.params;
    this.$store.commit('NETWORK_REQUEST_IN_PROGRESS');
    postApi.getPost({slug}).then(({data}) => {
      this.currentPost = data;
      this.$store.commit('POST_LOADED_MT', data);
    }).catch(({response}) => {
      this.currentPost = null;
      this.$store.commit('POST_NOT_FOUND_MT', {response, spec: {slug}});
    });
  },
}
</script>
<style scoped>
.post-container {
  min-height: 1000px;
}
</style>