import Vue from "vue";

export class EventBus {
  constructor() {
    this.listeners = {};
  }

  subscribe(eventName, callback) {
    if (! this.listeners[eventName]) {
      this.listeners[eventName] = [];
    }

    this.listeners[eventName].push(callback);
  }

  unsubscribe(eventName, callback) {
    if (! this.listeners[eventName]) {
      return;
    }

    this.listeners[eventName].splice(this.listeners[eventName].indexOf(callback), 1 );
  }

  broadcast(eventName, params) {
    if ( !this.listeners[eventName]){
      return ;
    }

    this.listeners[eventName].forEach(e => {
      e.call(null, params) ;
    })
  }
}


Vue.mixin( {
  beforeCreate() {
    const options = this.$options;
    if ( options.bus )
      this.$bus = options.bus;
    else if ( options.parent && options.parent.$bus )
      this.$bus = options.parent.$bus;
  }
});

export default new EventBus();