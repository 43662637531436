import DOMPurify from 'dompurify';

/**
 * Sanitizes content of posts.
 *
 * A few things that is required before allowing public users are:
 * - ensure selected list of tags are only available
 * - ensure images with public source are removed
 * - ensure dangerous images with src as scripts are removed.
 *
 * variations of this could be created for comments.
 * @param string
 * @returns {*}
 */
export default function sanitizePost(string) {
  const purify =  DOMPurify(window);
  return purify.sanitize(string);
}