<template>
  <div class="row row-x-center">
    <div id="main" class="s-content__main large-10 column">
      <article class="entry">
        <ce-title fromRoute/>
        <div class="post__editor__content" v-html="renderedMarkDown"></div>
      </article>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import postApi from "@/services/PostApi";

export default {
  name: 'AboutMe',
  data: function() {
    return {
      post: '',
    }
  },
  computed: {
    ...mapState([
      'auth',
    ]),
    renderedMarkDown() {
      if (this.post && this.post.content) {
        return this.$md.render(this.post.content);
      }
      return '';
    }
  },
  beforeCreate: function () {
    const slug = 'about';
    this.$store.commit('NETWORK_REQUEST_IN_PROGRESS');
    postApi.getPost({slug}).then(({data}) => {
      this.post = data;
      this.$store.commit('POST_LOADED_MT', data);
    }).catch( ({response}) => {
      this.post = null;
      this.$store.commit('POST_NOT_FOUND_MT', {response, spec: {slug}});
    });
  },
}
</script>

<style lang="scss" scoped>
.home {
  &__container {
    margin-top: 32px;
  }

  &__about-me {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  &__featured-posts {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}
</style>