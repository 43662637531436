<template>
  <div>
    <ce-title :dynamic-title="pageTitle" :dynamic-description="pageDescription"/>
    <h1> Edit Post </h1>
    <editor v-if="currentPost && currentPost.id" v-bind="{post: currentPost, imageUploadStatus, imageUrl, requestStatus,
        imageUploadMessage,error}" v-on:save-post="updatePost"></editor>
    <div v-if="!currentPost"> Loading the post to edit</div>
    <div v-if="currentPost && !currentPost.id"> No post id found</div>
  </div>
</template>

<script>
import Editor from '../components/forms/Editor/Editor';
import {mapState} from "vuex";
import postApi from "@/services/PostApi";

export default {
  name: 'edit-post-route',
  data: function (){
    return {
      currentPost: null
    }
  },
  computed: {
    ...mapState([
      'imageUrl',
      'imageUploadStatus',
      'imageUploadMessage',
      'requestStatus',
      'error',
    ]),
    pageTitle: function () {
      if (this.currentPost)
        return `Edit ${this.currentPost.topic}`;

      return null;
    },
    pageDescription: function () {
      return "";
    }
  },
  components: {
    Editor,
  },
  methods: {
    updatePost(post) {
      post.modified = new Date();
      this.$store.dispatch('UPDATE_POST', {...post, id: this.$route.params.id});
    },
  },
  beforeCreate: function () {
    const {id} = this.$route.params;
    this.$store.commit('NETWORK_REQUEST_IN_PROGRESS');
    postApi.getPost({id}).then( ({data}) => {
      this.currentPost = data;
      this.$store.commit('POST_LOADED_MT', data);
    }).catch( ({response}) => {
      this.currentPost = null;
      this.$store.commit('POST_NOT_FOUND_MT', {response, spec: {id}});
    });
  },
}
</script>
