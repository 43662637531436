<script>
import CeTag from "@/components/CeTag.vue";
import moment from "@/services/date";
import {truncateToWordLimit} from "@/transformers/ellipsis";
import intersectionObservedMixin from "@/mixins/intersectionObserved";
export default {
  name: "ArticleItem",
  props: ['post', 'type'],
  mixins: [intersectionObservedMixin],
  methods: {
    formatDisplayDate(post) {
      const {publishedDate, created, modified} = post;
      const displayDate = this.type === 'published' ? publishedDate : (modified ? modified : created);
      return moment(displayDate).format();
    },
    viewPost(post) {
      if (this.type === 'published') {
        this.$appRouter.pushRoute('VIEW_POST', {slug: post.slug});
      } else {
        this.$appRouter.pushRoute('MY_POST', {id: post.id});
      }
    },
    onViewPostClicked(ev, post) {
      ev.preventDefault();
      this.viewPost(post);
    },
    getPostRoute(post) {
      if (this.type === 'published') {
        return this.$appRouter.getRoute('VIEW_POST', {slug: post.slug});
      } else {
        return this.$appRouter.getRoute('MY_POST', {id: post.id});
      }
    },
    getExtract(post) {
      return truncateToWordLimit(post.extract, 150, true);
    },
  },
  components: {
    CeTag,
  },
}
</script>

<template>
  <article class="entry entry__padding out-of-view">
    <header class="entry__header">
      <h2 class="entry__title h1">
        <a @click="(ev) => {onViewPostClicked(ev, post)}" v-bind:href="getPostRoute(post)">{{post.topic}}</a>
      </h2>
      <div class="entry__meta">
        <ul>
          <li>{{ formatDisplayDate(post) }}</li>
          <li :key="tag" v-for="tag in post.tags" ><ce-tag v-bind:tag-name="tag"></ce-tag></li>
          <li>Shuku Torabi</li>
        </ul>
      </div>
    </header>
    <p class="entry__content" v-text="post.extract" />
  </article> <!-- end entry -->
</template>

<style lang="css" scoped>
.entry {
  min-height: 200px;
}
</style>