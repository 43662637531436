export default {
  data() {
    return {
      observer: null
    }
  },
  created() {
    let options = {
      root: this.$el,
      rootMargin: "0px",
      threshold: .15,
    };

    const callback = (entries, observer) => {
      entries.forEach( ({target, isIntersecting}) => {
        if (target && isIntersecting) {
          target.classList.remove('out-of-view');
        }
      });
    }

    this.observer = new IntersectionObserver(callback, options);
  }
};
