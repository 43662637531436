<template>
  <div class="post-metrics">
    <div class="row">
      <div class="large-4">
        <div class="icon-number">
          <div class="icon"><a v-on:click="showComments" class="gtm-post-interaction-link" id="show-comments" aria-label="show comments" href="#comments">
            <font-awesome-icon :icon="['far', 'comments']"/>
          </a></div>
          <div class="number"><span>{{ noOfComments > 0 ? noOfComments : '' }}</span></div>
        </div>
      </div>
      <div class="large-4">
        <a v-on:click="likePost" class="gtm-post-interaction-link" id="like-post" aria-label="like this article" href="#comments">
          <font-awesome-icon :icon="[ isLiked ? 'fas' : 'far', 'heart']"/>
        </a>
      </div>
      <div class="large-4">
        <a v-on:click="sharePost" class="gtm-post-interaction-link" id="share-post" aria-label="share this article" href="#comments">
          <font-awesome-icon :icon="[ isShared ? 'fas' : 'far', 'share-square']"/>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

export default {
  name: "PostMetrics",
  props: ['noOfComments', 'showCommentsCallback', 'postId', 'showShareModal'],
  data() {
    return {
      isLiked: false,
      isShared: false,
    }
  },
  methods: {
    likePost(ev) {
      ev.preventDefault();
      this.$store.dispatch('LIKE_POST', this.postId);
      this.isLiked = true;
    },
    sharePost(ev) {
      ev.preventDefault();
      this.$store.dispatch('SHARE_POST', this.postId);
      this.showShareModal();
      this.isShared = true;
    },
    showComments(ev) {
      ev.preventDefault();
      this.showCommentsCallback();
    }
  },
  components: {
    FontAwesomeIcon
  }
}
</script>

<style lang="scss" scoped>
.post-metrics {
  border-top: 1px;
  border-bottom: 1px solid #efefef;
  border-top: 1px solid #efefef;
  margin-bottom: 2em;

  a {
    color: #3e3e3e;
    width: 30px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  span {
    font-size: 1.4rem;
  }

  .icon-number {
    max-width: 100px;
    margin: auto;

    .icon {
      float: left;
      margin-left: 20px;
    }
  }
}
</style>
