<template>
  <div>
    <ce-title fromRoute/>
    <page-not-found/>
  </div>
</template>

<script>
import PageNotFound from "@/components/PageNotFound.vue";

export default {
  name: 'page-not-found-route',
  components: {
    PageNotFound,
  },
}
</script>

<style lang="scss" scoped>
.home {
  &__container {
    margin-top: 32px;
  }
}
</style>