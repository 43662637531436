<script>

export default {
  name: 'MdTextarea',
  props: ['content', 'label', 'id', 'rules', 'rows', 'cols', 'model', 'messageKeys'],
  components: {
    // ccInput,
  },
  data() {
    return {
      inputId: this.id + "Input",
      msgKeys: Object.keys(this.rules),
      showMdRenderer: false
    };
  },
  computed: {
    renderedMarkDown: function () {
      return this.$md.render(this.content)
    },
    labelDescription: function () {
      return `${this.label} ${this.showMdRenderer ? 'preview' : 'editor'}`;
    }
  },
  methods: {
    insertImage(url) {
      this.content = `![image](${url}){width=500px}` + this.content;
    },
    toggleMdRenderer() {
      this.showMdRenderer = !this.showMdRenderer;
    }
  },
}


</script>

<template>
  <div>
    <label class="form__label">
      <div class="label">{{ labelDescription }}</div>
      <div class="switch-view-toggle" @click="toggleMdRenderer"> [ Switch view
        <font-awesome-icon v-if="showMdRenderer" :icon="['far', 'file-code']"/>
        <font-awesome-icon v-if="!showMdRenderer" :icon="['far', 'eye']"/>
        ]
      </div>
    </label>

    <div v-if="! showMdRenderer" class="md-content-editor">
      <textarea :id="inputId" :rows="rows" :cols="cols" class="h-full-width"
                v-model.trim="model.$model"/>
    </div>
    <div v-if="showMdRenderer" class="md-renderer">
      <div v-html="renderedMarkDown"></div>
    </div>

    <div class="invalid-feedback alert-box alert-box--error hideit" v-for="key in messageKeys" :key="key">
      {{ messages[key].message }}
    </div>
  </div>
</template>

<style scoped lang="scss">
.switch-view-toggle {
  margin-left: auto;
  margin-right: 0;
}

.label {
  float: left;
}

.switch-view-toggle {
  margin-left: auto;
  margin-right: 0;
  max-width: 200px;
}

.md-renderer {
  max-height: 800px;
  overflow: auto;
  margin-bottom: 3.2rem;
}

.md-content-editor {
  max-height: 800px;
}

.md-content-editor textarea {
  min-height: 800px;
}

</style>