<template>
  <header class="s-header">
    <div id="top" class="row">
      <div :class="{'menu--collapsed': this.mode === 'collapsed'}" class="s-header__content column">
        <router-link to="/" aria-label="Home">
          <img v-if="this.mode === 'expanded'" :src="mainLogo" class="main-logo" alt="main logo - prefactor.io" :width="mainLogoStyles.width" :height="mainLogoStyles.height"/>
        </router-link>
        <p class="s-header__tagline">The thinking behind sustainable software engineering</p>
      </div>
    </div> <!-- end row -->

    <nav class="s-header__nav-wrap">
      <div class="row">
        <ul class="s-header__nav lg-menu-spread">
          <li @click="this.menuItemClicked" v-if="this.mode === 'collapsed' && showGroup('home')"
              :class="{ 'lg-menu-right-align': true, 'home-icon--collapsed': this.mode === 'collapsed', current: isCurrentPage('HOME')}">
            <router-link to="/" aria-label="Home">
              <img v-if="this.mode === 'collapsed'" :src="menuLogo" alt="menu logo - prefactor.io" class="menu-logo"
                   width="150px" height="40px"/>
              <template v-if="this.mode === 'expanded'">
                Home
              </template>
            </router-link>
          </li>
          <li @click="this.menuItemClicked" class="menu-item--first lg-menu-right-align"
              :class="{ current: isCurrentPage('HOME')}" v-if="isMobile() && showGroup('home')" href="#">
            <router-link to="/" aria-label="Home">
              Home
            </router-link>
          </li>
          <li @click="this.menuItemClicked" class="menu-item--first lg-menu-right-align"
              :class="{ current: isCurrentPage('ABOUT')}" v-if="showGroup('about')" href="#">
            <router-link :to="getRoute('ABOUT')">
              About
            </router-link>
          </li>
          <li @click="this.menuItemClicked" href="#"
              :class="{ 'lg-menu-right-align': true, current: isCurrentPage('ARTICLES')}"
              v-if="showGroup('articles')">
            <router-link v-bind:to="getRoute('ARTICLES')">
              Articles
            </router-link>
          </li>
          <li href="#"
              :class="{ 'lg-menu-right-align': true,
              'has-children': true, current: isCurrentPage('MY_POSTS')}"
              v-if="isAdminUser && showGroup('my-posts')">
            <a @click="(ev) => this.parentMenuClicked(ev, 'MY_POSTS_SUBMENU')"
               :class="{'sub-menu-is-open': this.openSubmenu === 'MY_POSTS_SUBMENU'}">
              My Posts
            </a>
            <ul :class="{'sub-menu-is-open': this.openSubmenu === 'MY_POSTS_SUBMENU'}">
              <li @click="this.menuItemClicked" href="#" v-if="isAdminUser && showGroup('my-posts')">
                <router-link @click="(ev) => this.parentMenuClicked(ev, 'MY_POSTS_SUBMENU')"
                             v-bind:to="getRoute('MY_POSTS')">
                  My drafts
                </router-link>
              </li>
              <li @click="this.menuItemClicked" href="#" v-if="isAdminUser && showGroup('create')">
                <router-link v-bind:to="getRoute('CREATE_POST')">
                  New article
                </router-link>
              </li>
              <li @click="this.menuItemClicked" href="#" v-if="isAdminUser && showPublish && showGroup('publish')">
                <a @click.self="publishPost">
                  Publish
                </a>
              </li>
              <li @click="this.menuItemClicked" href="#" v-if="isAdminUser && showUnpublish && showGroup('publish')">
                <a @click.self="unpublishPost">
                  Unpublish
                </a>
              </li>
              <li @click="this.menuItemClicked" href="#" v-if="isAdminUser && showHighlight && showGroup('publish')">
                <a @click.self="highlightPost">
                  Highlight
                </a>
              </li>
              <li @click="this.menuItemClicked" href="#" v-if="isAdminUser && showLowlight && showGroup('publish')">
                <a @click.self="lowlightPost">
                  Lowlight
                </a>
              </li>
              <li @click="this.menuItemClicked" href="#" v-if="isAdminUser && showFeature && showGroup('publish')">
                <a @click.self="featurePost">
                  Feature
                </a>
              </li>
              <li @click="this.menuItemClicked" href="#" v-if="isAdminUser && showUnfeature && showGroup('publish')">
                <a @click.self="unfeaturePost">
                  Unfeature
                </a>
              </li>
              <li @click="this.menuItemClicked" href="#" v-if="isAdminUser && this.currentPost && showGroup('edit')">
                <a @click.self="editPost">
                  Edit
                </a>
              </li>
              <li @click="this.menuItemClicked" href="#" v-if="isAdminUser && this.currentPost &&  showGroup('edit')">
                <a @click.self="deletePost">
                  Delete
                </a>
              </li>
              <li @click="this.menuItemClicked" href="#" v-if="isAdminUser && showGroup('save')">
                <a @click.self="savePost">
                  Save
                </a>
              </li>
            </ul>
          </li>
          <li @click="this.menuItemClicked"  :class="{ current: isCurrentPage('LOGIN'), 'lg-menu-right-align': true}" v-if="showGroup('login')"
              href="#">
            <router-link v-if="! isAdminUser" v-bind:to="getRoute('LOGIN')">
              <font-awesome-icon :icon="['far', 'user']"/>
              Login
            </router-link>
          </li>
          <li :class="{ 'has-children': true, 'lg-menu-right-align': true, 'sub-menu-is-open': this.openSubmenu === 'USER_MENU'}"
              v-if="isLoggedIn && showGroup('login')" href="#">
            <a @click="(ev) => this.parentMenuClicked(ev, 'USER_MENU')">
              <font-awesome-icon :icon="['far', 'user']"/>
              {{ userDisplayName }}
            </a>
            <ul :class="{'sub-menu-is-open': this.openSubmenu === 'USER_MENU'}">
              <li @click="this.menuItemClicked" href="#" v-if="isLoggedIn">
                <a @click="logout">
                  Logout
                </a>
              </li>
            </ul>
          </li>
        </ul> <!-- end #nav -->
      </div>
    </nav> <!-- end #nav-wrap -->

    <a :class="toggleMenuClass" href="#0" title="Menu" @click="toggleMenu"><span>Menu</span></a>
  </header> <!-- Header End -->
</template>

<script>
import {mapActions, mapState, mapGetters} from 'vuex';
import mainLogo from "../assets/img/Prefactor-logo-green.webp";
import menuLogo from "../assets/img/Prefactor-menu-logo.webp";

export default {
  name: "AppMenu",
  props: ['mode', 'groups', 'auth', 'isAdminUser', 'isLoggedIn'],
  data: function () {
    return {
      menuIsOpen: '',
      toggleBodyClass: '',
      openSubmenu: null,
      mainLogo,
      menuLogo,
      mainLogoDimensions: {
        width: 390,
        height: 142,
      }
    }
  },
  methods: {
    ...mapActions({
      logout: 'LOGOUT',
    }),
    toggleMenu(ev) {
      ev.preventDefault();
      if (window.matchMedia('(max-width: 800px)').matches) {
        this.menuIsOpen = !this.menuIsOpen;
        this.openSubmenu = null;
        document.body.classList.toggle('menu-is-open');
      }
    },
    menuItemClicked(ev) {
      ev.preventDefault();
      // at 800px and below
      if (this.isMobile()) {
        this.menuIsOpen = false;
        this.openSubmenu = null;
        document.body.classList.remove('menu-is-open');
      }
    },
    parentMenuClicked(ev, id) {
      ev.preventDefault();
      // at 800px and below
      if (this.isMobile()) {
        // this.$store.dispatch('TOGGLE_Submenu', this.menuIsOpen);
        if (this.openSubmenu === id) {
          this.openSubmenu = null;
        } else {
          this.openSubmenu = id;
        }
      }
    },
    isMobile() {
      return window.matchMedia('(max-width: 800px)').matches;
    },
    isLogoShrunk() {
      return window.matchMedia('(max-width: 500px)').matches;
    },
    showGroup(item) {
      return this.groups && this.groups.indexOf(item) >= 0;
    },
    deletePost() {
      if (window.confirm('Are you sure you want to delete ?')) {
        this.$store.dispatch('DELETE_POST', this.postId);
      }
    },
    publishPost() {
      if (window.confirm('Are you sure you want to publish ?')) {
        this.$store.dispatch('PUBLISH_POST', this.postId);
      }
    },
    unpublishPost() {
      if (window.confirm('Are you sure you want to unpublish ?')) {
        this.$store.dispatch('UNPUBLISH_POST', this.postId);
      }
    },
    highlightPost() {
      if (window.confirm('Are you sure you want to highlight this post ?')) {
        this.$store.dispatch('HIGHLIGHT_POST', this.postId);
      }
    },
    lowlightPost() {
      if (window.confirm('Are you sure you want to lowlight this post ?')) {
        this.$store.dispatch('LOWLIGHT_POST', this.postId);
      }
    },
    featurePost() {
      if (window.confirm('Are you sure you want to feature this post ?')) {
        this.$store.dispatch('FEATURE_POST', this.postId);
      }
    },
    unfeaturePost() {
      if (window.confirm('Are you sure you want to un-feature this post ?')) {
        this.$store.dispatch('UNFEATURE_POST', this.postId);
      }
    },
    savePost() {
      this.$bus.broadcast('SAVE_CURRENT_POST');
    },
    editPost() {
      this.$appRouter.pushRoute('EDIT_POST', {id: this.postId});
    },
    getRoute(name, params) {
      return this.$appRouter.getRoute(name, params);
    },
    isCurrentPage(name, params) {
      return this.$appRouter.isCurrentOrChildPage(name, params);
    }
  },
  computed: {
    ...mapState([
      'currentPost',
    ]),
    ...mapGetters([
      'isFeatured',
      'isHighlighted',
    ]),
    mainLogoStyles() {
      return this.isLogoShrunk() ? {
        width: Math.round(window.innerWidth * .8),
        height: Math.round(window.innerWidth * .8 * (this.mainLogoDimensions.height / this.mainLogoDimensions.width)),
      } : {
        height: this.mainLogoDimensions.height,
        width: this.mainLogoDimensions.width,
      }
    },
    toggleMenuClass() {
      return 'header-menu-toggle ' + (this.menuIsOpen ? 'is-clicked' : '');
    },
    userDisplayName() {
      return this.auth.displayName;
    },
    postId() {
      return this.currentPost ? this.currentPost.id : null;
    },
    showPublish() {
      if (!this.currentPost) return false;
      return this.$route.params.id ? !this.currentPost.isPublished : false;
    },
    showUnpublish() {
      if (!this.currentPost) return false;
      return this.$route.params.id ? this.currentPost.isPublished : false;
    },
    showHighlight() {
      if (this.currentPost) {
        return this.currentPost.isPublished && !this.isHighlighted(this.currentPost.id);
      }
      return false;
    },
    showLowlight() {
      if (this.currentPost) {
        return this.currentPost.isPublished && this.isHighlighted(this.currentPost.id);
      }
      return false;
    },
    showFeature() {
      if (this.currentPost) {
        return this.currentPost.isPublished && !this.isFeatured(this.currentPost.id);
      }
      return false;
    },
    showUnfeature() {
      if (this.currentPost) {
        return this.currentPost.isPublished && this.isFeatured(this.currentPost.id);
      }
      return false;
    },
  },
  // beforeCreate: function () {
  //   this.$store.dispatch('LOAD_FEATURES');
  // },
}
</script>

<style scoped>
a,
a a,
a.router-link-active {
  color: black;
}

.app-menu__logo {
  width: 32px;
  height: 32px;
}

.main-logo {
  max-height: 15rem;
}

.menu--collapsed {
  min-height: unset;
  padding-top: 3em;
  padding-bottom: unset;

  img {
    display: none;
  }

  p {
    display: none;
  }
}

.home-icon--collapsed {
  padding-top: .5em;

  img {
    max-height: 4rem;
  }

  a {
    padding-top: .5em;
  }
}

@media screen and (min-width: 800px) {
  .menu-item--first {
    margin-left: auto;
  }

  .lg-menu-spread {
    display: flex;
    width: 90%;
    flex-direction: row;
  }

  .lg-menu-right-align {
    margin-right: 0;
  }
}

@media screen and (max-width: 800px) {
  ul.sub-menu-is-open {
    display: block;
  }
}


</style>