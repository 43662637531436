<template>
  <div class="comment-respond">
    <!-- START respond -->
    <div id="respond">
      <h3>Add Comment</h3>
      <login-with-social v-bind:isLoggedInWithOAuth="isLoggedInWithOAuth" v-bind:issuer="getIssuer"
                         v-bind:displayName="getDisplayName">
        <span v-if="! userCanComment">Login with one of your accounts below to proceed:</span>
        <span v-if="userCanComment">Commenting as:</span>
      </login-with-social>

      <form name="contactForm" id="contactForm" method="post" action="" autocomplete="off" novalidate
            @submit.prevent="checkForm">
        <fieldset>

<!--          <template v-if="useEmailSelected">-->
<!--            <div class="form-field">-->
<!--              <input name="cName" id="cName" class="h-full-width" placeholder="Your Name" value="" type="text">-->
<!--            </div>-->

<!--            <div class="form-field">-->
<!--              <input name="cEmail" id="cEmail" class="h-full-width" placeholder="Your Email" value="" type="text">-->
<!--            </div>-->
<!--          </template>-->


          <div class="message form-field">
            <cc-input
                v-bind="{type: 'textarea', ...schema.comment , model: $v.comment, readonly: !userCanComment, 'outline': true}"
                class="h-full-width" v-bind:disabled="!isLoggedIn || saveInProgress"/>
          </div>
          <!-- Submit button -->
          <button id="submitBtn" type="submit" v-bind:disabled="!isLoggedIn || saveInProgress"
                  name="submit" class="btn btn--primary btn-wide btn--large h-full-width"
                  value="Add Comment"
          >
            <div v-if="saveInProgress">
              <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
              In Progress...
            </div>
            <div v-if="!saveInProgress">
              Submit
            </div>
          </button>
        </fieldset>
      </form> <!-- end form -->

    </div>
    <!-- END respond-->

  </div> <!-- end comment-respond -->
</template>

<script>
import {mapState} from "vuex";
import {NETWORK_REQUEST} from "@/store/initialise";
import LoginWithSocial from '../social/LoginWithSocial.vue';
import {clearForm, validateForm} from "../../services/Validation/validation.util";
import ccInput from "../forms/inputs/ccInput.vue";
import schema from "../forms/Comments/schema";
import validator from "../../services/Validation/Validation";

const issuers = ['google', 'linkedin', 'github'];

export default {
  name: "AddComment",
  props: ['postId', 'replyToCommentId', 'requestStatus'],
  validations: validator.getValidation(schema),
  methods: {
    addComment() {
      this.$store.dispatch('ADD_COMMENT', {
        content: this.comment,
        postId: this.postId,
        replyToCommentId: this.replyToCommentId,
        authorDisplayName: this.getDisplayName
      });
      this.comment = '';
      clearForm(schema, this.$v);
    },
    checkForm() {
      const isValid = validateForm(schema, this.$v);
      if (isValid === true) {
        this.addComment();
      }
    },
  },
  data() {
    return {
      comment: '',
      schema,
    };
  },
  computed: {
    ...mapState([
      'commentSaved',
      'currentPost',
    ],),
    saveInProgress: function () {
      return this.requestStatus === NETWORK_REQUEST.IN_PROGRESS;
    },
    isLoggedIn() {
      return this.$authService && this.$authService.isSessionCurrent();
    },
    isAdminUser() {
      return this.isLoggedIn && this.$authService.isAdminUser;
    },
    isSocialUser() {
      return this.isLoggedIn && this.$authService.isSocialUser;
    },
    isLoggedInWithOAuth() {
      return this.isLoggedIn && issuers.indexOf(this.$authService.issuer) >= 0;
    },
    userCanComment() {
      return this.isLoggedIn && issuers.indexOf(this.$authService.issuer) >= 0 ||
          this.isAdminUser;
    },
    getIssuer() {
      return this.$authService.issuer;
    },
    getDisplayName() {
      return this.$authService.displayName;
    }
  },
  components: {
    ccInput,
    LoginWithSocial,
  }
}
</script>

<style lang="scss" scoped>
.message {
  clear: both;
}

button[disabled] {
  opacity: 0.5; /* example: reduce opacity to indicate disabled state */
  cursor: not-allowed; /* example: change cursor to indicate disabled state */
}

fieldset {
  clear: left;
}
</style>
