<template>
  <div class="row row-x-center">
    <div id="main" class="s-content__main large-10 column">
      <article class="entry">
        <header class="entry__header">
          <h2 class="entry__title h1">
            <a href="#" title="">{{ post.topic }}</a>
          </h2>

          <div class="entry__meta">
            <ul>
              <li>{{ publishedDateFt }}</li>
              <li :key="tag" v-for="tag in post.tags">
                <ce-tag v-bind:tag-name="tag"></ce-tag>
              </li>
              <li>Shuku Torabi</li>
              <li>{{ readingTime }} mins read</li>
            </ul>
          </div>

        </header> <!-- entry__header -->

        <div class="entry__content-media">
          <img v-if="post.bannerImagePath"
               fetchpriority="high"
               :srcset="bannerImageSrcSet"
               :sizes="bannerImageSizes"
               :src="post.bannerImagePath.sm"
               :width="bannerImageMetadata.width"
               :height="bannerImageMetadata.height"
               alt="Banner image">
        </div>


        <!--    </div> &lt;!&ndash; end main &ndash;&gt;-->
        <!--    <div id="sidebar" class="s-content__sidebar large-4 column">-->

        <!--      <div class="widget widget&#45;&#45;categories">-->
        <!--        <h3 class="h6">Share.</h3>-->
        <!--        <share-modal v-if="modalShown" v-bind:modalShown="modalShown" v-bind:closeModalCallback="closeShareModal"/>-->
        <!--        <post-metrics v-if="post" v-bind:show-comments-callback="showComments"-->
        <!--                      v-bind:showShareModal="this.showShareModal"-->
        <!--                      v-bind:noOfComments="post.comments ? post.comments.length : 0" v-bind:post-id="post.id"/>-->
        <!--      </div>-->

        <!--      <div class="widget widget_text group">-->
        <!--        <h3 class="h6">Why this post ?</h3>-->
        <!--        <p>-->
        <!--          {{ postInspiration }}-->
        <!--        </p>-->
        <!--      </div>-->
        <!--      <div class="widget widget_tags">-->
        <!--        <h3 class="h6">Post Tags.</h3>-->

        <!--        <div class="tagcloud group">-->
        <!--          <ce-tag :key="tag" v-for="tag in post.tags" v-bind:tag-name="tag"></ce-tag>-->
        <!--        </div>-->
        <!--      </div>-->

        <!--    </div> &lt;!&ndash; end sidebar &ndash;&gt;-->
        <!--    <div id="article" class="s-content__main large-full column">-->
        <div class="entry__content">
          <p class="lead">
            {{ postInspiration }}
          </p>

          <div class="post__editor__content" v-html="renderedMarkDown"></div>

          <div class="widget widget_tags">

            <div class="tagcloud group">
              <ce-tag :key="tag" v-for="tag in post.tags" v-bind:tag-name="tag"></ce-tag>
            </div>
          </div>

          <ul v-if="this.hasPreviousLink || this.hasNextLink" class="entry__post-nav h-group">
            <li v-if="this.hasPreviousLink" class="prev"><a rel="prev" id="gtm-nav-prev" class="gtm-nav-next"
                                                            @click="(ev) => {onViewPostClicked(ev, previousSlug)}"
                                                            v-bind:href="getPostRoute(previousSlug)"><strong class="h6">Previous
              Article</strong> {{ previousTopic }}</a>
            </li>
            <li v-if="this.hasNextLink" class="next"><router-link rel="next" id="gtm-nav-next" class="gtm-nav-next" @click="(ev) => {onViewPostClicked(ev, nextSlug)}"
                                                        v-bind:to="getPostRoute(nextSlug)"><strong class="h6">Next
              Article</strong> {{ nextTopic }}</router-link></li>
          </ul>


        </div> <!-- entry__content -->
      </article> <!-- end entry -->
    </div>
  </div>
</template>

<script type="text/javascript">
import moment from "../services/date";
import CeTag from "./CeTag";
import sanitizeHtml from '../services/sanitizeHtml';

export default {
  name: 'post',
  props: ['post'],
  data() {
    const hasPreviousLink = this.post.previousLink && this.post.previousLink.slug;
    const hasNextLink = this.post.nextLink && this.post.nextLink.slug;

    const {slug: previousSlug, topic: previousTopic} = this.post.previousLink || {};
    const {slug: nextSlug, topic: nextTopic} = this.post.nextLink || {};
    return {
      modalShown: false,
      hasPreviousLink,
      hasNextLink,
      previousTopic,
      previousSlug,
      nextTopic,
      nextSlug,
    }
  },
  computed: {
    publishedDateFt() {
      return moment(this.post.publishedDate).format();
    },
    renderedMarkDown() {
      if (this.post && this.post.content) {
        const dirty = this.$md.render(this.post.content);
        return sanitizeHtml(dirty);
      }
      return '';
    },
    wordCount() {
      const pureText = this.post.content.replace('#', '').replace('\n', '');
      return pureText.split(' ').length;
    },
    readingTime() {
      return Math.floor(this.wordCount / 300);
    },
    bannerImageSrcSet() {
      const {lg, md, sm} = this.post.bannerImagePath || {};
      return `${lg} 1000w, ${md} 500w, ${sm} 240w`;
    },
    bannerImageMetadata() {
      const {metadata} = this.post.bannerImagePath || {};
      return metadata || {};
    },
    bannerImageSizes() {
      return '(max-width: 500px) 100vw, (max-width: 1000px) 90vw, 60vw';
    },
    postInspiration() {
      return this.post.inspiration ? this.post.inspiration : this.post.extract;
    }
  },
  methods: {
    onViewPostClicked(ev, slug) {
      ev.preventDefault();
      this.$appRouter.pushRoute('VIEW_POST', {slug});
    },
    getPostRoute(slug) {
      return this.$appRouter.getRoute('VIEW_POST', {slug});
    },
  },
  components: {
    CeTag,
  },
}
</script>

<style lang="scss">
.tagcloud a {
  background-color: #efefef;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 2.4rem;
  display: inline-block;
  margin: 0 0 1.2rem 1rem;
  padding: .8rem 1.6rem;
  position: relative;
  text-transform: uppercase;
  border-radius: 3px;
  text-decoration: none;
  letter-spacing: .2rem;
  color: #000000;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}
</style>
