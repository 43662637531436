export default {
  id: {
    id: 'id',
    label: 'id',
    default: '',
    rules: {
      required: true,
      length: 36,
    }
  },
  content: {
    id: 'content',
    label: 'content',
    default: '',
    rules: {
      required: true,
      minLength: 50,
    }
  },
  extract: {
    id: 'extract',
    label: 'extract',
    default: '',
    rules: {
      required: true,
      maxLength: 500,
    }
  },
  topic: {
    id: 'topic',
    label: 'topic',
    default: '',
    rules: {
      required: true,
      minLength: 5,
    }
  },
  mediaFiles: {
    id: 'mediaFiles',
    label: 'mediaFiles',
    default: '',
    rules: {
    }
  },
  tagsJoined: {
    id: 'tagsJoined',
    label: 'Tags',
    default: '',
    rules: {
      required: true,
    }
  },
  category: {
    id: 'category',
    label: 'Category',
    default: '',
    options:[
      {label: 'Content', value: 'Content'},
      {label: 'Article', value: 'Article'},
      {label: 'Interview', value: 'Interview'},
      {label: 'Technical guide', value: 'Technical guide'},
    ],
    rules: {
      required: true,
    }
  },
  bannerImageIndex: {
    id: 'bannerImageIndex',
    label: 'Banner Image Url',
    default: '',
    rules: {
      required: false,
      // url: true,
    }
  },
  nextLink: {
    id: 'nextLink',
    label: 'Next article',
    default: '',
    rules: {}
  },
  previousLink: {
    id: 'previousLink',
    label: 'Previous article',
    default: '',
    rules: {}
  },
  hideFromListing: {
    id: 'hideFromListing',
    label: 'Hide from listing',
    default: "",
    trueValue: "true",
    falseValue: "",
    rules: {}
  }
};
