<template>
  <div v-if="isVisible" class="ce-filter">
    <h6 class="h-remove-top">Selected Filters</h6>
    <button v-for="tag in tags" @click="(event) => toggleTag(tag)" :key="tag"
        id="submitBtn" type="submit"
        name="submit" class="btn btn--secondary btn--small">
      {{tag}}
      <font-awesome-icon :icon="['far', 'window-close']" />
    </button>
    <button v-if="filters.category" @click="(event) => nullifyCategory()"
            id="submitBtn" type="submit"
            name="submit" class="btn btn--secondary btn--small">
      {{filters.category}}
      <font-awesome-icon :icon="['far', 'window-close']" />
    </button>
  </div>
</template>

<script>
export default {
  name: "CeFilter",
  props: ['filters'],
  computed: {
    isVisible: function () {
      return this.filters && (this.filters.category || this.filters.tags && this.filters.tags.length > 0);
    },
    tags: function () {
      return this.filters && this.filters.tags ? this.filters.tags.map(t => t.trim().toLowerCase()) : [];
    }
  },
  methods: {
    toggleTag(text) {
      this.$emit('toggle-filter', text);
    },
    nullifyCategory() {
      this.$emit('nullify-category');
    }
  }
}
</script>

<style scoped>
.ce-filter {
  margin-top: 16px;
}
</style>