import intersection from "lodash.intersection";

export function filterPostsByTagsAndCategory(posts, {tags, category}) {
  let filterByTags = false;
  let filterByCategory = false;
  if (tags && tags.length > 0) {
    filterByTags = true;
  }
  if (category) {
    filterByCategory = true;
  }

  if (!(filterByCategory || filterByTags))
    return posts;

  if (filterByTags) {
    return posts.filter(p => {
      const pTags = p.tags.map(t => t.trim().toLowerCase());
      return intersection(pTags, tags.map(t => t.trim().toLowerCase())).length > 0;
    });
  }

  if (filterByCategory) {
    return posts.filter(p => {
      return p.category === category;
    })
  }
}

export function addCommentToTree(tree, replyToId, comment) {
  if (! replyToId || tree.length === 0) {
    tree.push(comment);
    return;
  }

  for (let i in tree) {
    const leaf = tree[i];
    if (leaf.id === replyToId) {
      leaf.children = leaf.children || [];
      leaf.children.push(comment);
      return;
    }
    else {
      if (leaf.children) {
        addCommentToTree(leaf.children, replyToId, comment)
      }
    }
  }
}

export function removeCommentFromTree(tree, commentId){
  let nodeIndex = null;
  for (let i in tree) {
    const leaf = tree[i];
    if (leaf.id === commentId) {
      nodeIndex = i;
      break;
    }
    else {
      if (leaf.children) {
        removeCommentFromTree(leaf.children, commentId);
      }
    }
  }
  if (nodeIndex) {
    tree.splice(nodeIndex, 1);
  }
}