<template>
  <div>
    <div class="row">
      <article-listing :posts="posts" type="published" />
    </div>
  </div>
</template>

<script>
  import ArticleListing from "./ArticleListing.vue";

  export default {
    name: "FeaturedPosts",
    props: ['featuredPosts'],
    data: function () {
      if (this.featuredPosts) {
        const {highlights: highlightedPosts, side: sidePosts} = this.featuredPosts;
        return { posts: [...highlightedPosts, ...sidePosts] };
      }

      return null;
    },
    components: {
      ArticleListing,
    }
  }
</script>