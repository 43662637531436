<template>
  <div>
    <div v-if="! contactRequestSubmitted" class="contact-us-form">
      <form novalidate @submit.prevent="checkForm">
        <div class="message form-field">
          <cc-input v-bind="{type: 'email', ...schema.email , model: $v.email, 'outline': true, placeholder:'your@email.com'}" class="h-full-width"
                    v-bind:disabled="saveInProgress"/>
        </div>
        <div class="message form-field">
          <cc-input v-bind="{type: 'textarea', ...schema.message , model: $v.message, 'outline': true}"
                    class="h-full-width" v-bind:disabled="saveInProgress"/>
        </div>
        <!-- Submit button -->
        <button id="submitBtn" type="submit" v-bind:disabled="saveInProgress"
                name="submit" class="btn btn--primary btn-wide btn--large h-full-width"
                value="Add Comment">
          <div v-if="saveInProgress">
            <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
            In Progress...
          </div>
          <div v-if="!saveInProgress">
            Submit
          </div>
        </button>
      </form>
    </div>
    <div v-if="contactRequestSubmitted" md="12" lg="12">
      <p>Your contact request has been submitted successfully. We will get back to you on your provided email
        address. </p>
    </div>
  </div>
</template>

<script>
import {NETWORK_REQUEST} from "@/store/initialise";
import schema from "./schema";
import ccInput from "../inputs/ccInput.vue";
import validator from "../../../services/Validation/Validation";
import {mapState} from "vuex";
import {validateForm} from "../../../services/Validation/validation.util";


export default {
  name: "ContactUsForm",
  props: ['userEmail', 'userDisplayName'],
  validations: validator.getValidation(schema),
  methods: {
    checkForm() {
      const isValid = validateForm(schema, this.$v);
      if (isValid === true) {
        this.sendContactUsRequest();
      }
    },
    sendContactUsRequest() {
      this.$store.dispatch('CONTACT_US_REQUEST', {message: this.message, userEmail: this.email});
      this.message = '';
    },
  },
  data() {
    return {
      message: '',
      email: '',
      schema,
    };
  },
  computed: {
    ...mapState([
      'contactRequestSubmitted',
    ]),
    saveInProgress: function () {
      return this.requestStatus === NETWORK_REQUEST.IN_PROGRESS;
    }
  },
  components: {
    ccInput,
  }
}
</script>

<style lang="scss" scoped>
.contact-us-form{
  clear: both;
}
</style>
