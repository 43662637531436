<template>
  <div>
      <ce-title fromRoute/>
      <ce-filters v-bind:filters="filters" v-on:toggle-filter="toggleFilterTag"
                  v-on:nullify-category="toggleCategory(null)"></ce-filters>
      <article-listing :posts="filteredPosts" type="published"/>
  </div>
</template>

<script>
import {mapState} from "vuex";
import CeFilters from "../components/CeFilter";
import ArticleListing from "../components/post/ArticleListing.vue";

export default {
name: 'timeline-route',
  computed: {
    ...mapState([
      'filteredPosts',
      'filters',
    ]),
  },
  components: {
    ArticleListing,
    CeFilters,
  },
  methods: {
    toggleFilterTag(tag) {
      this.$store.commit('TOGGLE_FILTER_TAG', tag);
      this.$store.dispatch('FILTER_POSTS');
    },
    toggleCategory(category) {
      this.$store.commit('SET_FILTER_CATEGORY', category);
      this.$store.dispatch('FILTER_POSTS');
    }
  },
  beforeCreate: function () {
    const {tag, category} = this.$route.query;
    const tags = (tag ? [tag] : []).map(x => x.trim().toLowerCase());
    this.$store.commit('SET_FILTERS', {tags, category});
    this.$store.dispatch('LOAD_PUBLISHED_POSTS_FILTERED');
  },
}
</script>