import axios from 'axios' ;
import authService from "@/services/Auth";

export class ContactApi {
  constructor(baseUrl, authService) {
    this.authService = authService
    this.baseUrl = baseUrl;

    this.axiosInstance = axios.create({
      baseURL: baseUrl,
      headers: {
        'x-api-token': null
      }
    });

    this.axiosInstance.interceptors.request.use((config) => {
      if (this.authService.token) {
        config.headers['x-api-token'] = this.authService.token;
      }
      return config;
    });

    this._posts = [];
  }

  sendContactUsRequest({userEmail, message}) {
    return this.axiosInstance.post('/contact_us', {userEmail, message});
  }
}

export default new ContactApi('/api', authService);
