<template>
  <div class="uploader-box">
    <div v-if="uploadIsInProgress" class="spinner-box">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div v-if="error" class="toast">
      <span class="sr-only">Upload message {{ error }}</span>
    </div>

    <div class="uploader-container">
      <media-files v-bind:list="mediaFiles" @on-insert="onInsert" @on-media-removed="removeMedia" class="media-files" />
      <picture-input
        v-bind:class="{ 'in-progress': uploadIsInProgress }"
        ref="pictureInput"
        width="600"
        height="600"
        margin="16"
        accept="image/jpeg,image/png"
        size="10"
        button-class="btn"
        :custom-strings="{
          upload: '<h1>Bummer!</h1>',
          drag: 'Drag & Drop Image'
        }"
        @change="onChange">
      </picture-input>
    </div>

    <button
        v-bind:disabled="uploadIsInProgress"
        @click="uploadImage" v-if="!!selectedImage">
      <div v-if="uploadIsInProgress">
        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
        Loading...
      </div>

      <div v-if="!uploadIsInProgress">
        Upload
      </div>
    </button>

  </div>
</template>

<script>
/**
 * todo: show progress bar for the uploader
 */
import PictureInput from 'vue-picture-input'
import {NETWORK_REQUEST} from '../../store/initialise';
import MediaFiles from "./MediaFiles";
import {mapState} from "vuex";

export default {
  name: 'ImageUpload',
  props: ['imageUrl', 'imageType', 'uploadStatus', 'id', 'mediaFiles'],
  data() {
    return {
      selectedImage: this.image,
    }
  },
  computed: {
    ...mapState([
      'imageUploadStatus',
      'imageUploadMessage',
    ]),
    uploadIsInProgress: function () {
      return this.uploadStatus === NETWORK_REQUEST.IN_PROGRESS;
    },
    error: function () {
      return this.uploadStatus === NETWORK_REQUEST.FAILED;
    }
  },
  components: {
    PictureInput,
    MediaFiles,
  },
  methods: {
    onChange(image) {
      if (image) {
        this.selectedImage = image
      } else {
        console.warn('FileReader API not supported: use the <form>!')
      }
    },
    onRemoved() {
      this.selectedImage = null;
    },
    uploadImage() {
      const {imageType, id} = this;
      const {name, type} = this.$refs.pictureInput.file;
      const file = this.$refs.pictureInput.image;

      this.$store.dispatch('UPLOAD_IMAGE', {image: file, imageType, fileType: type, name, id});
    },
    onInsert(url) {
      this.$emit('on-insert', url)
    },
    removeMedia(media, idx) {
      this.$emit('on-media-removed', media, idx);
    }
  }
}
</script>

<style scoped>
.uploader-box {
  position: relative;
}

.spinner-box {
  position: absolute;
  height: 100%;
  width: 100%;
  color: black;
  background-color: #CCCCCC;
  opacity: 1;
}

.spinner-border {
  position: absolute;
  top: 50%;
  left: 50%;
}

.picture-input.in-progress {
  opacity: .1;
}

.uploader-container {
  display: flex;
}

</style>