<template>
    <a :href="getTagsLink(text)" @click="onClick" title="" class="gtm-post-tag" rel="category tag">{{ text }}</a>
</template>

<script>

  export default {
    name: "CeTag",
    props: ['tagName', 'iconName'],
    data: function () {
      if (!this.tagName) {
        console.error("A tag-name must be passed to the <ce-tag> components");
      }

      let tName = this.tagName.trim();
      let tNmDisp;

      if (tName !== "") {
        tName = tName.toLowerCase(),
          tNmDisp = tName[0].toUpperCase() + tName.substr(1, tName.length - 1);
      }

      return {
        text: tNmDisp,
      }
    },
    methods: {
      onClick(ev) {
        ev.preventDefault();
        const tName = this.text.trim().toLowerCase();
        this.$appRouter.pushRoute('ARTICLES_WITH_TAG', {tag: tName})
      },
      getTagsLink(tag) {
        return `/articles/?tag=${tag}`;
      },
    }
  }
</script>