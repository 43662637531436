const momentFormatToDateOptions = (format =  'DD MMM YYYY') => {
  const mapping = {
    'DD MMM YYYY': {
      month: 'short',
      year: 'numeric',
      day: '2-digit',
    }
  }

  return mapping[format];
}
export default (dateStr) => {
  return {
    format: (format) => {
      if (! dateStr){
        console.error(`invalid date provided ${dateStr}`);
        return '';
      }

      const options = momentFormatToDateOptions(format);
      if(! options) {
        console.error(`unknown date format provided ${format}`);
        return '';
      }

      return new Intl.DateTimeFormat('en-GB', options).format(new Date(dateStr));
    }
  }
}