<script>
import CeTag from "../components/CeTag.vue";
import intersectionObservedMixin from "@/mixins/intersectionObserved";

export default {
  name: "Sidebar",
  props: ['categories', 'tags', 'featuredPosts'],
  components: {
    CeTag,
  },
  mixins: [intersectionObservedMixin],
  computed: {
    popularPosts() {
      if (this.featuredPosts) {
        const {side: sidePosts} = this.featuredPosts;
        return [...sidePosts];
      }
      return [];
    },
    tagsList() {
      return Object.keys(this.tags || {});
    },
    categoriesList() {
      const cats = this.categories;
      return Object.keys(cats || {}).map(x => ({category: x, count: cats[x]}));
    }
  },
  methods: {
    onViewPostClicked(ev, post) {
      ev.preventDefault();
      this.$appRouter.pushRoute('VIEW_POST', {slug: post.slug});
    },
    getPostRoute(post) {
      return this.$appRouter.getRoute('VIEW_POST', {slug: post.slug});
    },
    getCategoriesLink(cat) {
      return `/articles/?category=${cat}`;
    },
  },
}
</script>

<template>
  <div id="sidebar" class="s-content__sidebar large-4 column out-of-view">

    <!--        <div class="widget widget&#45;&#45;search">-->
    <!--          <h3 class="h6">Search</h3>-->
    <!--          <form action="#">-->
    <!--            <input type="text" value="Search here..." onblur="if(this.value == '') { this.value = 'Search here...'; }" onfocus="if (this.value == 'Search here...') { this.value = ''; }" class="text-search">-->
    <!--            <input type="submit" value="" class="submit-search">-->
    <!--          </form>-->
    <!--        </div>-->

    <div class="widget widget--categories">
      <h3 class="h6">Categories.</h3>
      <ul>
        <li :key="cat.category" v-for="cat in categoriesList">
          <a :href="getCategoriesLink(cat.category)" title="">{{ cat.category }}</a> ({{ cat.count }})
        </li>
      </ul>
    </div>

    <div class="widget widget_text group large-screen-only">
      <h3 class="h6">Inspiration.</h3>

      <p>
        Prefactor.io aims to focus on topics that are less covered in technical blogs.
        These include but are not limited to software architecture, team building, digital transformations and
        technical direction of large scale developments. Read more about this in our
        <router-link to="about">about</router-link>
        page.

      </p>
    </div>

    <div class="widget widget_tags">
      <h3 class="h6">Post Tags.</h3>

      <div class="tagcloud group">
        <ce-tag :key="tag" v-for="tag in tagsList" v-bind:tag-name="tag"></ce-tag>
      </div>
    </div>

    <div class="widget widget_popular">
      <h3 class="h6">Featured Posts.</h3>

      <ul class="link-list">
        <li :key="post.slug" v-for="post in popularPosts"><a @click="(ev) => {onViewPostClicked(ev, post)}"
                                                             v-bind:href="getPostRoute(post)">{{ post.topic }}</a>
        </li>
      </ul>
    </div>
  </div> <!-- end sidebar -->
</template>