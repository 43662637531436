<template>

  <!-- Footer
    ================================================== -->
  <footer v-if="isVisible" class="s-footer">

    <div class="row s-footer__top">
      <div class="column">
        <ul class="s-footer__social">
          <li><a href="#0"><i class="fab fa-facebook-f" aria-hidden="true"></i></a></li>
          <li><a href="#0"><i class="fab fa-twitter" aria-hidden="true"></i></a></li>
          <li><a href="#0"><i class="fab fa-youtube" aria-hidden="true"></i></a></li>
          <li><a href="#0"><i class="fab fa-vimeo-v" aria-hidden="true"></i></a></li>
          <li><a href="#0"><i class="fab fa-instagram" aria-hidden="true"></i></a></li>
          <li><a href="#0"><i class="fab fa-linkedin" aria-hidden="true"></i></a></li>
          <li><a href="#0"><i class="fab fa-skype" aria-hidden="true"></i></a></li>
        </ul>
      </div>
    </div> <!-- end footer__top -->

    <div class="row s-footer__bottom">

      <div class="large-6 tab-full column s-footer__info">
        <h3 class="h6">About Prefactor.io</h3>
        <p>
          The idea behind prefactor.io is to focus on topics that are generally not covered in technical blogs.
          These include software architecture, team building, road-maps and technical
          direction of large scale developments. Read more about this in our
          <router-link to="about">about</router-link>
          page.
        </p>
      </div>

      <div class="large-6 tab-full column">
        <div class="row">
          <div class="tab-full column">
            <h3 class="h6">Navigate</h3>

            <ul class="s-footer__list s-footer-list--nav group">
                <li><a @click="routeTo('HOME')" href="/">Home</a></li>
                <li><a @click="routeTo('Articles')" href="/articles">Articles</a></li>
                <li><a @click="routeTo('ABOUT')" href="/about">About</a></li>
                <li><a @click="routeTo('CONTACT_US')" href="/contact-us">Contact us</a></li>
            </ul>
          </div>
        </div>
      </div>

      <div class="ss-copyright">
        <span>© Prefactor.io 2023</span>
        <span>Design of this page was inspired by <a href="https://www.styleshout.com/">StyleShout</a></span>
      </div>

    </div> <!-- end footer__bottom -->


    <div :class="{'ss-go-top': true, 'link-is-visible': isWindowScrolled}">
      <a class="smoothscroll" title="Back to Top" href="#top" @click="smoothScrollToTop">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0l8 9h-6v15h-4v-15h-6z"/></svg>
      </a>
    </div> <!-- end ss-go-top -->

  </footer> <!-- end Footer-->
</template>

<script>
import debounce from 'lodash/debounce';
import intersectionObservedMixin from "@/mixins/intersectionObserved";

export default {
  name: 'PageFooter',
  props: ['isVisible'],
  data() {
    return {
      canonicalHost: process.env.VUE_APP_CANONICAL_HOSTNAME,
      isWindowScrolled: false,
    }
  },
  created() {
    this.handleDebouncedScroll = debounce(this.handleScroll, 500);
    window.addEventListener('scroll', this.handleDebouncedScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleDebouncedScroll);
  },
  methods: {
    routeTo(route) {
      this.$appRouter.pushRoute(route);
    },
    handleScroll(){
      this.isWindowScrolled = window.scrollY > 800;
    },
    smoothScrollToTop(ev){
      ev.preventDefault();
      const node = document.querySelector('#top');
      node.scrollIntoView({block: "center", behavior: 'smooth'});
    },
  }
}
</script>

<style scoped>
</style>