export default {
  email: {
    id: 'email',
    label: 'Email',
    default: '',
    rules: {
      required: true,
      email: true,
    }
  },
  message: {
    id: 'message',
    label: 'Message',
    default: '',
    rows: 5,
    rules: {
      required: true,
      minLength: 10,
      maxLength: 500,
    }
  },
};
