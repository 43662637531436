

export function setClipboard(text, onSuccessCb, onErrorCb) {
    navigator.clipboard.writeText(text).then(
        () => {
            onSuccessCb && onSuccessCb();
        },
        (err) => {
            console.error('error copying the clipboard item', err)
            onErrorCb && onErrorCb(err);
        },
    );
}
