<template>
  <div class="row row-x-center">
    <div id="main" class="s-content__main large-10 column">
      <article class="entry">
        <ce-title fromRoute/>
        <header class="entry__header">
          <h2 class="entry__title h1">
            {{ title }}
          </h2>
        </header>
        <section>
          <figure>
            <img class="img-fluid" src="/assets/404-man-in-desert.png" alt="404 a man lost in a desert in formal suit"/>
            <figcaption>
              Ever found yourself at the wrong place.
            </figcaption>
          </figure>
          <div class="body-message">
            <slot></slot>
          </div>
        </section>
      </article>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageNotFound",
  props: ['title'],
}
</script>

<style scoped lang="scss">
  figure {
    margin-top: 2rem;

    figcaption {
      margin:auto;
      max-width: 60%;
      display: block;
    }
  }
</style>