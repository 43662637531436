import Vue from 'vue'
import Vuex from 'vuex'
import authService from '../services/Auth';
import postApi from '../services/PostApi'
import authApi from "../services/AuthApi";
import contactApi from "../services/ContactApi";
import imageUploadApi from "../services/ImageUploadApi";
import {router} from "../services/routerService";
import {addCommentToTree, removeCommentFromTree} from "@/services/transformers";

Vue.use(Vuex);

export const NETWORK_REQUEST = {
  NOT_SENT: 'NOT_SENT',
  SUCCESSFUL: 'SUCCESSFUL',
  IN_PROGRESS: 'IN_PROGRESS',
  FAILED: 'FAILED'
};

const asyncRequest = (
  commit,
  [apiInstance, method , ...params],
  mutator,
  action
) => {
  commit('NETWORK_REQUEST_IN_PROGRESS');
  apiInstance[method].apply(apiInstance, ...params)
    .then( response => {
      commit('NETWORK_REQUEST_SUCCEEDED');
      commit(mutator, ...params)
      return response;
    })
    .catch(
      error => {
        commit('NETWORK_REQUEST_FAILED');
        commit('API_FAILURE', {error, action, params})
      }
    );
}

const emptyAuthState = {userId: null, email: null, token: null, expiry: null, userRoles:[], displayName: null, issuer: null};

const initialState = {
  auth: emptyAuthState,
  filters: {
    tags: [],
    category: null,
  },
  featuredPosts: null,
  filteredPosts: [],
  tags: {},
  categories: {},
  posts : [],
  publishedPosts: [],
  publishedPostNames: [],
  postSaved: null,
  error: {},
  lastSynced: null,
  failedAttempts: [],
  currentPost: null,
  currentPostComments: {tree: [] , count:0 },
  imageUrl: null,
  imageUploadStatus: NETWORK_REQUEST.NOT_SENT,
  imageUploadMessage: null,
  requestStatus: NETWORK_REQUEST.NOT_SENT,
  contactRequestSubmitted: false,
};

// const clonedState = JSON.parse(JSON.stringify(initialState));
// the root, initial state object
const state = Object.assign({}, initialState);

// const clearState = (state) => {
//   Object.keys(clonedState).forEach(x => {
//     if ( typeof clonedState[x] === 'object') {
//       if (Array.isArray(clonedState[x])) {
//         state[x] = clonedState[x].concat([]);
//       } else {
//         state[x] = Object.assign({},clonedState[x]);
//       }
//     }
//     else
//       state[x] = clonedState[x]
//   });
// }

const actions = (postApi, authApi, contactApi, imageUploadApi) => ({
  REGISTER: ({commit}, email, password) => {
    authApi.register(email, password).
    then(
      response => commit('REGISTRATION_SUCCESSFUL_MT', response.data)
    )
    .catch(
      error => commit('API_FAILURE', {error, action:'Register', params: {}})
    )
  },
  REQUEST_LOGIN_CODE: ({commit}, {email, password}) => {
    authApi.requestLoginCode(email, password).
      then(
        response => commit('LOGIN_CODE_SENT_SUCCESSFUL_MT', response.data)
      )
      .catch(
        (error) => commit('LOGIN_CODE_SENT_FAILURE_MT', error.response ? error.response.data : {
          message: error
        })
      )
  },
  LOGIN: ({commit}, {email, loginCode}) => {
    authApi.login(email, loginCode).
    then(
        response => commit('LOGIN_SUCCESSFUL_MT', response.data)
    )
    .catch(
        (error) => commit('LOGIN_FAILURE_MT', error.response ? error.response.data : {
          message: error
        })
    )
  },
  LOGOUT: ({commit}, {email}) => {
    commit('LOGOUT_CLIENT_MT')
    authApi.logout(email).
      then(
        () => {
          commit('LOGOUT_SERVER_SUCCESSFUL_MT');
          router.replace('/');
        }
      )
      .catch(
        () => commit('LOGOUT_SERVER_FAILURE_MT')
      )
  },
  SOCIAL_MEDIA_LOGGED_IN: ({commit}, response) => {
    commit('LOGIN_SUCCESSFUL_MT', response);
    router.replace(authService.fromUrl);
    authService.fromUrl = "";
  },
  LOGIN_WITH_SOCIAL_MEDIA: ({commit}, {redirect, currentUrl}) => {
    authService.fromUrl = currentUrl;
    commit('LOGIN_WITH_REDIRECT_MT', redirect);
    window.location.href = redirect;
  },
  UPLOAD_IMAGE: ({commit}, {image, imageType, fileType, name, id, metadata}) => {
    commit('UPLOAD_IMAGE_IN_PROGRESS_MT');
    imageUploadApi.uploadImage({image, imageType, fileType, name, id, metadata}).then(
      response => {
        console.log('commiting image upload ', response );
        
        commit('UPLOAD_IMAGE_MT', response.data, id)
      }
    )
    .catch(
      error => {
        commit('API_FAILURE', {error, message: error.response.data.message, action:'Upload Image'});
        return commit('UPLOAD_IMAGE_FAILURE_MT')
      }
    );
  },
  FILTER_POSTS: ({commit, state}) => {
    const posts = postApi.filterBy(state.publishedPosts, state.filters);
    commit ('FILTER_POSTS_MT', posts) ;
  },
  LOAD_PUBLISHED_POSTS_FILTERED: ({commit}) => {
    commit('NETWORK_REQUEST_IN_PROGRESS');
    postApi.getAllPosts(true)
      .then(
        response => {
          commit ('PUBLISHED_POSTS_LOADED_MT', response.data);
          const filtered = postApi.filterBy( response.data, state.filters);
          commit('FILTER_POSTS_MT', filtered);
        }
      )
      .catch(
        error => commit('API_FAILURE', {error, response: error.response, action:'LOAD_POSTS', params: {}})
      );
  },
  LOAD_PUBLISHED_POSTS_NAMES: ({commit}) =>{
    postApi.getAllPublishedPostNames()
      .then(
        response => {
          commit ('PUBLISHED_POSTS_NAMES_LOADED_MT', response.data);
        }
      )
      .catch(
        error => commit('API_FAILURE', {error, response: error.response, action:'LOAD_PUBLISHED_POSTS_NAMES', params: {}})
      );
  },
  LOAD_POSTS: ({commit}) => {
    commit('NETWORK_REQUEST_IN_PROGRESS');
    postApi.getAllPosts()
    .then(
      response => commit('POSTS_LOADED_MT', response.data)
    )
    .catch(
      error => commit('API_FAILURE', {error, response: error.response, action:'LOAD_POSTS', params: {}})
    );
  },
  LOAD_FEATURES_AND_PARAMETERS: ({commit}) => {
    postApi.getFeaturedPostsAndParameters()
      .then(
        response => {
          commit('FEATURES_LOADED_MT', response.data)
          commit('PARAMETERS_LOADED_MT', response.data)
        }
      )
      .catch(
        error => commit('API_FAILURE', {error, response: error.response, action:'LOAD_FEATURES', params: {}})
      );
  },
  LOAD_FEATURES: ({commit}) => {
    postApi.getFeaturedPosts()
      .then(
        response => commit('FEATURES_LOADED_MT', response.data)
      )
      .catch(
        error => commit('API_FAILURE', {error, response: error.response, action:'LOAD_FEATURES', params: {}})
      );
  },
  LOAD_PARAMETERS: ({commit}) => {
    postApi.getParameters()
      .then(
        response => commit('PARAMETERS_LOADED_MT', response.data)
      )
      .catch(
        error => commit('API_FAILURE', {error, response: error.response, action:'LOAD_PARAMETERS', params: {}})
      );
  },
  LOAD_POST: ({commit}, spec) => {
    commit('NETWORK_REQUEST_IN_PROGRESS');
    postApi.getPost(spec)
      .then(
        response => {
          commit('POST_LOADED_MT', response.data)
          commit('NETWORK_REQUEST_SUCCEEDED');
        }
      )
      .catch(
        error => {
          commit('POST_NOT_FOUND_MT', {error, action:'LOAD_POST', response: error.response, spec})
        }
      );
  },
  CREATE_POST: ({commit}, post) => {
    const newPost = {...post};
    commit('NETWORK_REQUEST_IN_PROGRESS');
    postApi.addPost(newPost)
    .then(
      () => commit('ADD_POST_MT', newPost)
    )
    .catch(
      error => commit('API_FAILURE', {error, action:'ADD_POST', params: {post}})
    );
  },
  DELETE_POST: ({commit}, postId) => {
    postApi.deletePost(postId)
    .then(
      () => commit('DELETE_POST_MT', postId)
    )
    .catch(
      error => commit('API_FAILURE', {error, action:'DELETE_POST', params: {postId}})
    );
  },
  PUBLISH_POST: ({commit}, postId ) => {
    commit('NETWORK_REQUEST_IN_PROGRESS');
    postApi.publishPost(postId)
      .then( response => {
        commit('NETWORK_REQUEST_SUCCEEDED');
        commit('PUBLISH_POST_MT', postId);
        return response;
      })
      .catch(
        error => {
          commit('API_FAILURE', {error, action:'PUBLISH_POST', params: {postId}});
        }
      );
  },
  UNPUBLISH_POST: ({commit}, postId ) => {
    commit('NETWORK_REQUEST_IN_PROGRESS');
    postApi.unpublishPost(postId)
      .then( response => {
        commit('NETWORK_REQUEST_SUCCEEDED');
        commit('UNPUBLISH_POST_MT', postId)
        return response;
      })
      .catch(
        error => {
          commit('NETWORK_REQUEST_FAILED');
          commit('API_FAILURE', {error, action:'UNPUBLISH_POST', params: {postId}})
        }
      );
  },
  HIGHLIGHT_POST: ({commit}, postId) => {
    asyncRequest(
       commit,
       [postApi,'highlightPost', [postId]],
      'HIGHLIGHT_POST_MT',
      'HIGHLIGHT_POST',
    );
  },
  LOWLIGHT_POST: ({commit}, postId) => {
    asyncRequest(
      commit,
      [postApi,'lowlightPost', [postId]],
      'LOWLIGHT_POST_MT',
      'LOWLIGHT_POST',
    );
  },
  FEATURE_POST: ({commit}, postId) => {
    asyncRequest(
       commit,
       [postApi,'featurePost', [postId]],
      'FEATURE_POST_MT',
      'FEATURE_POST',
    );
  },
  UNFEATURE_POST: ({commit}, postId) => {
    asyncRequest(
       commit,
       [postApi,'unfeaturePost', [postId]],
      'UNFEATURE_POST_MT',
      'UNFEATURE_POST',
    );
  },
  UPDATE_POST: ({commit}, post ) => {
    commit('NETWORK_REQUEST_IN_PROGRESS');
    postApi.updatePost(post)
    .then( response => {
      commit('NETWORK_REQUEST_SUCCEEDED');
      commit('UPDATE_POST_MT', post)
      return response;
    })
    .catch(
      error => {
        commit('NETWORK_REQUEST_FAILED');
        commit('API_FAILURE', {error, action:'EDIT_POST', params: {post}})
      }
    );
  },
  TOGGLE_POST: ({commit}, post) => {
    const newPost = Object.assign({}, post);
    newPost.done = ! newPost.done;
    postApi.updatePost(newPost)
    .then(
      () => commit('TOGGLE_POST_MT', {oldPostIndex: state.posts.indexOf(post), newPost})
    )
    .catch(
      error => commit('API_FAILURE', {error, action:'TOGGLE_POST', params: {post}})
    );
  },
  ADD_COMMENT: ({commit}, {content, postId, replyToCommentId, authorDisplayName}) => {
    const newCommentPayload = {content, replyToCommentId};
    commit('NETWORK_REQUEST_IN_PROGRESS');
    postApi.addComment(postId, newCommentPayload)
      .then(
        (response) => {
          const { id: commentId, authorId } = response.data || {};
          commit('ADD_COMMENT_MT', {authorId, content, postId, commentId, replyToCommentId, authorDisplayName});
        }
      )
      .catch(
        error => commit('API_FAILURE', {error, action:'ADD_COMMENT', params: newCommentPayload})
      );
  },
  DELETE_COMMENT: ({commit}, commentId) => {
    commit('NETWORK_REQUEST_IN_PROGRESS');
    postApi.deleteComment(commentId)
      .then(
          () => {
            commit('DELETE_COMMENT_MT', commentId);
          }
      )
      .catch(
          error => commit('API_FAILURE', {error, action:'DELETE_COMMENT', params: commentId})
      );
  },
  LIKE_POST: ({commit}, postId) => {
    postApi.likePost(postId)
    .then(
        () => {
          commit('POST_LIKED', postId);
        }
    )
    .catch(
        error => commit('API_FAILURE', {error, action:'LIKE_POST', params: postId})
    );
  },
  SHARE_POST: ({commit}, postId) => {
    postApi.sharePost(postId)
        .then(
            () => {
              commit('POST_SHARED', postId);
            }
        )
        .catch(
            error => commit('API_FAILURE', {error, action:'SHARE_POST', params: postId})
        );
  },
  CONTACT_US_REQUEST: ({commit}, {userEmail, message}) => {
    contactApi.sendContactUsRequest({userEmail, message})
        .then(
            () => {
              commit('CONTACT_US_REQUEST_SENT', {isSuccessful: true});
            }
        )
        .catch(
            error => commit('API_FAILURE', {error, action:'CONTACT_US_REQUEST', params: userEmail})
        );
  },

});

// define the possible mutations that can be applied to our state
const mutations = {
  NETWORK_REQUEST_FAILED: (state) => {
    state.requestStatus = NETWORK_REQUEST.FAILED;
  },

  NETWORK_REQUEST_SUCCEEDED: (state) => {
    state.requestStatus = NETWORK_REQUEST.SUCCESSFUL;
  },

  NETWORK_REQUEST_IN_PROGRESS: (state) => {
    state.requestStatus = NETWORK_REQUEST.IN_PROGRESS;
  },
  LOGIN_CODE_SENT_SUCCESSFUL_MT:(state, {email, success}) => {
    state.auth = {
      ...emptyAuthState,
      email,
      loginCodeSent: success,
      message: 'Please enter the login code sent to your email or phone number.'
    };
  },
  LOGIN_CODE_SENT_FAILURE_MT:(state) => {
    state.auth = {
     ...emptyAuthState,
      message: 'Login code was not sent successfully.'
    };
  },
  LOGIN_SUCCESSFUL_MT:(state, authState) => {
    authService.init(authState);
    authService.storeAuthState();
    state.auth = authState;
  },
  LOGIN_FAILURE_MT:(state, {message} ) => {
    state.auth = { ...emptyAuthState, message };
  },
  LOGOUT_CLIENT_MT:(state) => {
    authService.init(emptyAuthState);
    authService.clearAuthState();
    state.auth = {...emptyAuthState};
  },
  LOGOUT_SERVER_SUCCESSFUL_MT: ()=> {
    console.info('Logout succeeded on both client and server.');
  },
  LOGOUT_SERVER_FAILURE_MT:() => {
    console.error('Serverside logout failed !');
  },
  TOGGLE_FILTER_TAG: (state, tag) => {
    const tags = state.filters.tags;
    if (tags.includes(tag) ) {
      tags.splice( tags.indexOf(tag), 1);
    } else {
      tags.push(tag)
    }
    state.filters.tags = tags ;
  },
  SET_FILTER_CATEGORY: (state, category) => {
    state.filters.category = category;
  },
  SET_FILTERS: (state, filters) => {
    state.filters = filters;
  },
  FILTER_POSTS_MT: (state, posts) => {
    state.filteredPosts = posts;
  },
  POSTS_LOADED_MT:(state, posts) => {
    state.requestStatus = NETWORK_REQUEST.SUCCESSFUL;
    const count = state.posts.length;
    state.posts.splice(0, count, ...posts);
    state.lastSynced = new Date();
  },
  PUBLISHED_POSTS_LOADED_MT: (state, posts) => {
    state.requestStatus = NETWORK_REQUEST.SUCCESSFUL;
    state.publishedPosts = posts;
  },
  PUBLISHED_POSTS_NAMES_LOADED_MT: (state, publishedPostNames) => {
    state.publishedPostNames = publishedPostNames;
  },
  FEATURES_LOADED_MT: (state, features) => {
    state.featuredPosts = features;
  },
  PARAMETERS_LOADED_MT: (state, {tags, categories}) => {
    state.tags = tags;
    state.categories = categories;
  },
  POST_LOADED_MT:(state, post) => {
    state.requestStatus = NETWORK_REQUEST.SUCCESSFUL;
    state.currentPost = post ;
    state.currentPostComments = post.comments;
    state.lastSynced = new Date();
  },
  POST_NOT_FOUND_MT:(state, {error, response, spec}) => {
    state.requestStatus = NETWORK_REQUEST.FAILED;
    state.error= {
      error: error ? error.toString() : '',
      message: response.data.message,
      status: response.status,
      spec,
      timestamp: new Date()
    };
  },
  API_FAILURE:(state, {error, response, action, params}) => {
    if ( response && response.data && response.data.status === 403) {
      console.error('Authentication failed');
      state.requireLogin = true;
    }
    state.requestStatus = NETWORK_REQUEST.FAILED;
    state.error= {
      error: error.toString(),
      message: error.response.data.message,
      timestamp: new Date()
    };
    state.failedAttempts.push({action, params});
  },
  INIT_POST_MT: (state) => {
    state.currentPost = {};
  },
  ADD_POST_MT:(state, post) =>{
    state.posts.push(post);
    state.requestStatus = NETWORK_REQUEST.SUCCESSFUL;
    state.error = null;
    state.imageUploadMessage = null;
    state.postSaved = true;
    state.currentPost = {...post, isPublished: false, publishedDate: null};
  },
  ADD_COMMENT_MT:(state, {content, displayName, timestamp, commentId, authorId, replyToCommentId, authorDisplayName}) =>{
    state.requestStatus = NETWORK_REQUEST.SUCCESSFUL;
    state.error = null;
    state.commentSaved = true;
    state.currentPost.comments = state.currentPost.comments || {tree: [], count: 0};
    let tree = state.currentPost.comments.tree;
    addCommentToTree(tree, replyToCommentId, { content: content, displayName, timestamp, id: commentId, authorId, authorDisplayName});
    state.currentPostComments = {tree, count: state.currentPostComments.count + 1}
  },
  DELETE_COMMENT_MT:(state, commentId) =>{
    state.requestStatus = NETWORK_REQUEST.SUCCESSFUL;
    state.currentPost.comments = state.currentPost.comments || {tree: [], count: 0};
    let tree = state.currentPost.comments.tree;
    removeCommentFromTree(tree, commentId);
    state.currentPostComments = {tree, count: state.currentPostComments.count - 1}
  },
  DELETE_POST_MT:(state, postId) => {
    const oldPostIndex = state.posts.findIndex( x  => x.id === postId);
    state.posts.splice( oldPostIndex, 1);
  },
  UPDATE_POST_MT: (state, post) => {
    const oldPostIndex = state.posts.findIndex( x  => x.id === post.id);
    if (oldPostIndex){
      state.posts.splice(oldPostIndex, 1, post) ;
    } else {
      state.posts.push(post);
    }
  },
  UNPUBLISH_POST_MT: (state) => {
    state.currentPost = {...state.currentPost, isPublished: false, publishedDate: null}
  },
  PUBLISH_POST_MT: (state) => {
    state.currentPost = {...state.currentPost, isPublished: true, publishedDate: new Date()}
  },
  TOGGLE_POST_MT:(state, {oldPostIndex, newPost}) => {
    state.posts[oldPostIndex].done = newPost.done;
  },
  UPLOAD_IMAGE_MT: (state, {mimeType, original, sm, md, lg, metadata}) => {
    state.imageUploadStatus = NETWORK_REQUEST.SUCCESSFUL;
    state.imageUrl = sm ;
    state.currentPost.mediaFiles = state.currentPost.mediaFiles || [];
    state.currentPost.mediaFiles.push({mimeType, original, sm, md, lg, metadata});
  },
  UPLOAD_IMAGE_IN_PROGRESS_MT: (state) => {
    state.imageUploadStatus = NETWORK_REQUEST.IN_PROGRESS
    state.imageUrl = null ;
  },
  UPLOAD_IMAGE_FAILURE_MT: (state) => {
    state.imageUrl = null ;
    state.imageUploadStatus = NETWORK_REQUEST.FAILED;
  },
  HIGHLIGHT_POST_MT: (state, postId) => {
    const post = state.posts.find( x  => x.id === postId);
    state.featuredPosts.highlights.push(post) ;
  },
  LOWLIGHT_POST_MT: (state, postId) => {
    const oldPostIndex = state.featuredPosts.highlights.findIndex( x  => x.id === postId);
    state.featuredPosts.highlights.splice( oldPostIndex, 1);
  },
  FEATURE_POST_MT: (state, postId) => {
    state.featuredPosts.side = (state.featuredPosts.side || []).push(postId) ;
  },
  UNFEATURE_POST_MT: (state, postId) => {
    const oldPostIndex = state.featuredPosts.side.findIndex( x  => x.id === postId);
    state.featuredPosts.side.splice( oldPostIndex, 1);
  },
  CONTACT_US_REQUEST_SENT: (state, {isSuccessful}) => {
    state.contactRequestSubmitted = isSuccessful;
  },
  AUTH_INITIALISED_MT:(state, authState) => {
    state.auth = authState;
  },
};

const getters = {
  // auth: state => state.auth,
  posts: state => state.posts,
  currentPost: state => state.currentPost,
  error: state => state.error,
  lastSynced: state => state.lastSynced,
  failedAttempts: state => state.failedAttempts,
  requireLogin: state => state.requireLogin,
  imageUploadStatus: state => state.imageUploadStatus,
  imageUrl: state => state.imageUrl,
  imageUploadMessage: state => state.imageUploadMessage,
  postSaved: state => state.postSaved,
  filters: state => state.filters,
  featuredPosts: state => state.featuredPosts,
  filteredPosts: state => state.filteredPosts,
  publishedPosts: state => state.publishedPosts,
  isFeatured: state => postId => state.featuredPosts && state.featuredPosts.side.find(x => x.id === postId),
  isHighlighted: state => postId => state.featuredPosts && state.featuredPosts.highlights && state.featuredPosts.highlights.find(x => x.id === postId),
}

// const api = window.location.href.indexOf('localhost') >= 0 ? 'http' : 'https';
// const portPostfix = process.env.VUE_APP_API_PORT != '80' ? `:${process.env.VUE_APP_API_PORT}` : '';
// const HOST = `${api}://${window.location.hostname}${portPostfix}`;


// const authWatch = store => {
//   // called when the store is initialized
//   store.subscribe((mutation) => {
//     const {type, payload} = mutation ;
//     if ( type === 'LOGIN_SUCCESSFUL_MT' || type === 'LOGIN_FAILURE_MT') {
//       authService.init(payload);
//     }
//
//     if (type === 'LOGOUT_CLIENT_MT_MT' || type === 'LOGOUT_CLIENT_MT') {
//       authService.init(emptyAuthState) ;
//     }
//   });
// }

// create the Vuex instance by combining the state and mutations objects
// then export the Vuex store for use by our components
const store = new Vuex.Store({
  state,
  actions: actions(postApi, authApi, contactApi, imageUploadApi),
  mutations,
  getters,
  // plugins: [authWatch],
});

export default store ;